export enum SearchCategoryValues {
    ASSETS = 'assets',
    RESEARCH = 'research',
    FLASHNOTES = 'flashnotes',
    CHARTS = 'charts',
    AUTHORS = 'authors',
    DASHBOARDS = 'dashboards',
    PROPOSALS = 'proposals',
    NEWS = 'news',
    NEWSLETTERS = 'newsletters',
    PODCASTS = 'podcasts',
    PAGES = 'pages',
    RECENT_SEARCHES = 'recent-searches',
}
