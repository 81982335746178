import { createApi } from '../../config/create-api';

import {
    AcceptInviteRequest,
    AcceptInviteResponse,
    COMPANY_ENDPOINTS,
    DeleteInviteRequest,
    DeleteInviteResponse,
    DeleteMemberRequest,
    DeleteMemberResponse,
    GetCompanyInvitesResponse,
    GetCompanyResponse,
    PostAddMemberRequest,
    PostAddMemberToCompanyResponse,
    PostUpdateCompanyRequest,
} from './company.model';

const companyApi = createApi(build => ({
    get: {
        company: build<GetCompanyResponse>({
            cachePrefix: 'company',
            query: () => {
                return {
                    uri: COMPANY_ENDPOINTS.GET.COMPANY(),
                };
            },
        }),
        companyInvites: build<GetCompanyInvitesResponse>({
            cachePrefix: 'company-invites',
            query: () => {
                return {
                    uri: COMPANY_ENDPOINTS.GET.INVITES(),
                    params: {
                        active: true,
                    },
                };
            },
        }),
    },
    post: {
        updateCompany: build<any, PostUpdateCompanyRequest>({
            cachePrefix: 'update-company',
            query: ({ name }) => {
                return {
                    uri: COMPANY_ENDPOINTS.POST.UPDATE_COMPANY(),
                    options: {
                        method: build.Method.Post,
                        body: { name },
                    },
                };
            },
        }),
        addMember: build<PostAddMemberToCompanyResponse, PostAddMemberRequest>({
            cachePrefix: 'add-company-member',
            query: ({ email, role }) => {
                return {
                    uri: COMPANY_ENDPOINTS.POST.INVITE_USER(),
                    options: {
                        method: build.Method.Post,
                        body: { email, role },
                    },
                };
            },
        }),
        acceptInvite: build<AcceptInviteResponse, AcceptInviteRequest>({
            cachePrefix: 'accept-company-invite',
            query: ({ token, sessionToken }) => {
                return {
                    uri: COMPANY_ENDPOINTS.POST.ACCEPT_INVITE(),
                    options: {
                        method: build.Method.Post,
                        body: { token },
                        sessionToken,
                    },
                };
            },
        }),
    },
    delete: {
        member: build<DeleteMemberResponse, DeleteMemberRequest>({
            cachePrefix: 'delete-company-member',
            query: ({ email }) => {
                return {
                    uri: COMPANY_ENDPOINTS.DELETE.MEMBER(),
                    options: {
                        method: build.Method.Delete,
                        body: { email },
                    },
                };
            },
        }),
        invite: build<DeleteInviteResponse, DeleteInviteRequest>({
            cachePrefix: 'delete-company-invite',
            query: ({ id }) => {
                return {
                    uri: COMPANY_ENDPOINTS.DELETE.INVITE({ id }),
                    options: {
                        method: build.Method.Delete,
                    },
                };
            },
        }),
    },
}));

export { companyApi };
