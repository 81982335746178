import { Divider, FlexBox, FlexBoxProps } from '@blockworks/ui/components';

import { AuthAlert } from '../auth-alert';

import { CredentialsSignIn, CredentialsSignInProps } from './credentials-sign-in';
import { OAuthButton } from './oauth-button';

type SignInProps = CredentialsSignInProps & FlexBoxProps;

/** A comprehensive sign in component that includes oauth buttons and a credentials form. */
const SignIn = ({ forgotPasswordLink, onSuccess, children, ...rest }: SignInProps) => {
    return (
        <FlexBox col gap="md" {...rest}>
            <AuthAlert />
            <OAuthButton provider="google" onSuccess={onSuccess}>
                Sign In With Google
            </OAuthButton>
            <OAuthButton provider="microsoft-entra-id" onSuccess={onSuccess}>
                Sign In With Outlook
            </OAuthButton>
            <Divider betweenText="or" />
            <CredentialsSignIn onSuccess={onSuccess} forgotPasswordLink={forgotPasswordLink} />
            {children}
        </FlexBox>
    );
};

export type { SignInProps };
export { SignIn };
