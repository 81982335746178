import { CreatableCombobox } from './creatable';

/**
 * The base `Combobox` component isn't developed yet; It should be a searchable select/dropdown component, without the ability to create custom options.
 * We can enhance the API when the component is needed or when we're ready to migrate the current `Select` component to `Combobox`.
 */
const Combobox = {
    Creatable: CreatableCombobox,
};

export { Combobox };
