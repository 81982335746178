import type { UnknownObj } from '../../../typescript';

import { buildEndpoint } from './build-endpoint';

type BuildShape<T> = (makeEndpoint: typeof buildEndpoint) => T;

type EndpointsShape = {
    get?: UnknownObj;
    post?: UnknownObj;
    put?: UnknownObj;
    delete?: UnknownObj;
    patch?: UnknownObj;
};

const createApi = <T extends EndpointsShape>(buildShape: BuildShape<T>) => buildShape(buildEndpoint);

export { createApi };
