import { createContext, PropsWithChildren, useState } from 'react';
import Analytics, { AnalyticsInstance } from 'analytics';

import { StringUnion } from '../../typescript';

const AnalyticsContext = createContext<AnalyticsInstance>(undefined!);
AnalyticsContext.displayName = 'Analytics';

type AnalyticsProviderProps = PropsWithChildren<{
    app: StringUnion<'blockworks-research' | 'blockworks.co'>;
    version: string;
    registerPlugins?: (includeClientPlugins: boolean) => any[];
}>;

const AnalyticsProvider = ({
    children,
    app,
    version = '1.0.0',
    registerPlugins = () => [],
}: AnalyticsProviderProps) => {
    const [analytics] = useState(
        Analytics({
            app,
            /**
             * @see https://github.com/DavidWells/analytics/issues/445
             * @ts-ignore type is wrong inside analytics package - wait for them to patch */
            version,
            debug: process.env.NODE_ENV === 'development',
            plugins: registerPlugins(
                typeof window !== 'undefined' &&
                    (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development'),
            ),
        }),
    );

    return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
};

export { AnalyticsContext, AnalyticsProvider };
