import { formatDistanceStrict } from '@blockworks/platform/libs/date-fns';

export const getTimeDistance = (date: string | number | Date) => {
    if (!date) return '';
    let formattedDate: number = 0;
    if (typeof date === 'number') {
        const rawDateVal = new Date(date);
        // some date numbers are in seconds, some are in milliseconds
        if (rawDateVal.getFullYear() <= 1970) {
            formattedDate = date * 1000;
        } else {
            formattedDate = date;
        }
    } else if (typeof date === 'string') {
        formattedDate = new Date(date).getTime();
    } else {
        formattedDate = date.getTime();
    }
    if (formattedDate) {
        return formatDistanceStrict(formattedDate, new Date(), { addSuffix: true });
    } else {
        return '';
    }
};

export const getFormattedDuration = (seconds: number) => {
    // 'H:MM:SS' format
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const minStr = minutes < 10 ? `0${minutes}` : minutes;
    const remainingSeconds = Math.round(seconds % 60);
    const secStr = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
    if (hours > 0) {
        return `${hours}:${minStr}:${secStr}`;
    }
    return `${minStr}:${secStr}`;
};
