import { forwardRef } from 'react';
import { FloatingPortal } from '@floating-ui/react';
import { useFloating } from '@floating-ui/react-dom';
import { useCombobox } from 'downshift';

import { cn, mapClassNamesToSlots, type TVStyleProps } from '../../../style-system';
import { ChevronSelectorVerticalIcon, XCloseIcon } from '../../icon';
import { type SimpleFieldOptions, useFieldProps } from '../field/context';

import { type ComboboxProps } from './combobox.model';
import { comboboxFloatingConfig, itemToString, makeHandleOnInputKeyDown, useCreatableCombobox } from './combobox.utils';
import { creatableComboboxStyles } from './creatable.styles';

type CreatableComboboxProps = ComboboxProps & {
    onCreate?: (newItem: string) => void;
    createLabel?: string;
    createdItemProps?: {
        description?: string;
        label?: string;
    };
} & SimpleFieldOptions &
    TVStyleProps<typeof creatableComboboxStyles>;

/**
 * A searchable select component, with the ability to create custom value that
 * doesn't exist in the `options` array.
 */
const CreatableCombobox = forwardRef<HTMLInputElement, CreatableComboboxProps>(function CreateableCombobox(
    {
        placeholder,
        createLabel = 'Create',
        createdItemProps,
        options,
        value = '',
        defaultValue = '',
        size: sizeProp = 'md',
        variant,
        onValueChange,
        onCreate,
        onInputChange,
        classNames,
        ...rest
    },
    ref,
) {
    const { size = sizeProp, fieldProps, rootProps } = useFieldProps(rest);
    const editingDisabled = fieldProps.disabled || fieldProps.readOnly;

    const {
        filteredOptions,
        setFilteredOptions,
        selectedOption,
        inputValue,
        setInputValue,
        canCreate,
        handleOnInputChange,
    } = useCreatableCombobox({ options, value, createdItemProps, onInputChange });
    const {
        isOpen,
        highlightedIndex,
        selectedItem,
        getToggleButtonProps,
        getMenuProps,
        getInputProps,
        getItemProps,
        selectItem,
    } = useCombobox({
        items: filteredOptions,
        defaultInputValue: defaultValue,
        inputValue: inputValue,
        selectedItem: selectedOption,
        itemToString,
        stateReducer: (state, actionAndChanges) => {
            /** if diabled/readonly, disable state changes */
            if (editingDisabled) return state;
            return actionAndChanges.changes;
        },
        onSelectedItemChange: ({ selectedItem }) => {
            const selectedValue = selectedItem?.value ?? '';
            onValueChange?.(selectedValue);
            setInputValue(selectedValue);

            /** reset filtered options */
            setFilteredOptions(options);
        },
    });

    /** callbacks for event handlers */
    const handleClear = () => {
        if (editingDisabled) return;
        setInputValue('');
        selectItem(null);
        onValueChange?.('');
    };
    const handleCreate = () => {
        const createdValue = inputValue?.trim();
        if (!createdValue) {
            return handleClear();
        }
        setFilteredOptions([...options, { value: createdValue, label: createdValue }]);
        onCreate?.(createdValue);
        onValueChange?.(createdValue);
    };

    /** dropdown placement management */
    const { refs, floatingStyles } = useFloating<HTMLDivElement>({
        open: isOpen,
        ...comboboxFloatingConfig,
    });
    /** styles */
    const styles = mapClassNamesToSlots(creatableComboboxStyles, {
        size,
        disabled: fieldProps.disabled,
        readOnly: fieldProps.readOnly,
        isOpen,
        variant,
        classNames,
    });

    return (
        <>
            <div className={styles.base} {...rootProps} ref={refs.setReference}>
                <div className={styles.inputWrapper}>
                    <input
                        {...getInputProps({
                            ref,
                            placeholder,
                            className: styles.input,
                            ...fieldProps,
                            onKeyDown: makeHandleOnInputKeyDown(handleCreate),
                            onChange: handleOnInputChange,
                            onFocus: fieldProps.onFocus,
                            onBlur: fieldProps.onBlur,
                            autoComplete: 'off',
                        })}
                    />
                    <span className={styles.actionWrapper}>
                        {!!value && <XCloseIcon className={styles.clearButton} onClick={handleClear} title="Clear" />}
                        <ChevronSelectorVerticalIcon {...getToggleButtonProps({ className: styles.indicator })} />
                    </span>
                </div>
                <FloatingPortal>
                    <ul {...getMenuProps({ ref: refs.setFloating })} style={floatingStyles} className={styles.dropdown}>
                        {isOpen &&
                            filteredOptions.map((option, index) => {
                                return (
                                    <li
                                        key={option.value}
                                        className={cn(styles.option, {
                                            'bg-surface-selected hover:bg-surface-selected':
                                                selectedItem?.value === option.value,
                                            /** ensures hover bg color when using arrow keys for navigation */
                                            'bg-surface-muted': highlightedIndex === index,
                                        })}
                                        {...getItemProps({
                                            index,
                                            item: option,
                                        })}
                                    >
                                        <span className={styles.optionInner}>
                                            <span className={styles.optionLabel}>{option.label}</span>
                                            {option.description && (
                                                <span className={styles.optionDescription}>{option.description}</span>
                                            )}
                                        </span>
                                    </li>
                                );
                            })}
                        {canCreate && (
                            <li
                                className={styles.optionCreate}
                                {...getItemProps({
                                    index: filteredOptions.length,
                                    item: { value: inputValue, label: inputValue },
                                    onClick: handleCreate,
                                })}
                            >
                                {createLabel} &quot;{inputValue}&quot;
                            </li>
                        )}
                    </ul>
                </FloatingPortal>
            </div>
        </>
    );
});

export type { CreatableComboboxProps };
export { CreatableCombobox };
