import type { AnyObj, ObjUnion, StringUnion, UnknownObj } from '../../../typescript';
import { type BaseUrl } from '../base-urls';
import type { FetcherOptions } from '../fetcher';
import { fetcher } from '../fetcher';
import { HttpMethod } from '../http-method';
import { UserAgent } from '../user-agent';

import { flow, getBaseUrl, makeUnencodedParams, stripEmpty } from './build-endpoint.utils';

type DictOrVoid = UnknownObj | void;

type Query<RequestParams extends DictOrVoid> = (args: RequestParams) => {
    uri: string;
    unencodedParams?: RequestParams extends void
        ? AnyObj
        : ObjUnion<Partial<Record<keyof RequestParams, string | number>>>;
    params?: RequestParams extends void ? AnyObj : ObjUnion<Partial<RequestParams>>;
    options?: Omit<FetcherOptions<any>, 'url' | 'originalResponse'>;
    overrideBaseUrl?: StringUnion<BaseUrl>;
};

type QueryFn<RequestParams extends DictOrVoid, EndpointResponse> = (
    reqParams: RequestParams,
    tools: { baseFetch: typeof fetcher },
) => Promise<EndpointResponse>;

type PrepareFetcher<RequestParams extends DictOrVoid> = (
    queryReturnValue: ReturnType<Query<RequestParams>>,
) => FetcherOptions<any>;

type BuildEndpointOpts<EndpointResponse, RequestParams extends DictOrVoid> = {
    baseUrl?: BaseUrl;
    cachePrefix: string;
} & (
    | {
          query: Query<RequestParams>;
          queryFn?: never;
      }
    | {
          query?: never;
          queryFn: QueryFn<RequestParams, EndpointResponse>;
      }
);

interface FetchEndpoint<Res, RequestArgs extends DictOrVoid> {
    (args?: RequestArgs): Promise<Res>;
    cachePrefix: string;
}

const buildEndpoint = <EndpointResponse = any, RequestArgs extends DictOrVoid = void>({
    baseUrl = 'research-api',
    cachePrefix,
    query,
    queryFn,
}: BuildEndpointOpts<EndpointResponse, RequestArgs>) => {
    const prepareFetcher: PrepareFetcher<RequestArgs> = ({
        uri,
        params,
        unencodedParams,
        options = {},
        overrideBaseUrl,
    }) => {
        const base = getBaseUrl(baseUrl, overrideBaseUrl);
        let url = `${base}${uri}`,
            search = '';

        if (unencodedParams) {
            search = makeUnencodedParams(stripEmpty(unencodedParams));
        }

        if (params) {
            const searchStr = new URLSearchParams(stripEmpty(params)).toString();
            if (searchStr) {
                if (search) search += '&';
                search += searchStr;
            }
        }

        if (search) {
            url += `?${search}`;
        }

        return { url, ...options };
    };

    let fetchEndpoint: FetchEndpoint<EndpointResponse, RequestArgs>;

    if (queryFn) {
        const prepareQueryFn = (requestArgs: RequestArgs) => [requestArgs, { baseFetch: fetcher }];
        fetchEndpoint = flow(prepareQueryFn, queryFn) as FetchEndpoint<EndpointResponse, RequestArgs>;
    } else {
        fetchEndpoint = flow(
            query,
            prepareFetcher,
            fetcher,
            /** casted here so TS doesn't complain about `cachePrefix` not being assigned  */
        ) as FetchEndpoint<EndpointResponse, RequestArgs>;
    }

    fetchEndpoint.cachePrefix = cachePrefix;
    return fetchEndpoint;
};

buildEndpoint.Method = HttpMethod;
buildEndpoint.UserAgent = UserAgent;

export type { DictOrVoid, FetchEndpoint, QueryFn };
export { buildEndpoint };
