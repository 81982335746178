export enum BeehiivPublicationIds {
    Daily = 'pub_2d09a047-9004-4638-9d8f-96778cc945a8',
    Weekly = 'pub_3e2b572f-5604-4251-af96-228698616d1f',
    ResearchDaily = 'pub_0d66d0da-6de0-4f51-b043-4e1ec87d970e',
    Events = 'pub_794f0ed8-b4bf-48ee-828e-7586a5e9575d',
    Webinars = 'pub_461d2572-cd56-493c-8dc2-0580fda18730',
    Empire = 'pub_252bfc8d-0faa-4c84-9c29-22f27253e365',
    Lightspeed = 'pub_8ea337de-9a78-4628-afe5-cca9099a8dea',
    ForwardGuidance = 'pub_4b1f3010-0e25-41c8-8bd3-fdc6db7ecfb9',
}

export enum BeehiivPublicationSlugs {
    Daily = 'daily',
    Weekly = 'weekly',
    ResearchDaily = 'research',
    Events = 'events',
    Webinars = 'webinars',
    Empire = 'empire',
    Lightspeed = 'lightspeed',
    ForwardGuidance = 'forwardguidance',
}

export enum BeehiivPublicationTitles {
    Daily = 'Blockworks Daily',
    Weekly = 'Blockworks Weekly',
    ResearchDaily = '0xResearch Daily',
    Events = 'Events',
    Webinars = 'Webinars',
    Empire = 'The Empire',
    Lightspeed = 'Lightspeed',
    ForwardGuidance = 'Forward Guidance',
}

export type BeehiivCustomField = {
    name: string;
    value: string | number | boolean;
};

const publicationDataMap = {
    Daily: {
        id: BeehiivPublicationIds.Daily,
        slug: BeehiivPublicationSlugs.Daily,
        title: BeehiivPublicationTitles.Daily,
    },
    Weekly: {
        id: BeehiivPublicationIds.Weekly,
        slug: BeehiivPublicationSlugs.Weekly,
        title: BeehiivPublicationTitles.Weekly,
    },
    ResearchDaily: {
        id: BeehiivPublicationIds.ResearchDaily,
        slug: BeehiivPublicationSlugs.ResearchDaily,
        title: BeehiivPublicationTitles.ResearchDaily,
    },
    Events: {
        id: BeehiivPublicationIds.Events,
        slug: BeehiivPublicationSlugs.Events,
        title: BeehiivPublicationTitles.Events,
    },
    Webinars: {
        id: BeehiivPublicationIds.Webinars,
        slug: BeehiivPublicationSlugs.Webinars,
        title: BeehiivPublicationTitles.Webinars,
    },
    Empire: {
        id: BeehiivPublicationIds.Empire,
        slug: BeehiivPublicationSlugs.Empire,
        title: BeehiivPublicationTitles.Empire,
    },
    Lightspeed: {
        id: BeehiivPublicationIds.Lightspeed,
        slug: BeehiivPublicationSlugs.Lightspeed,
        title: BeehiivPublicationTitles.Lightspeed,
    },
    ForwardGuidance: {
        id: BeehiivPublicationIds.ForwardGuidance,
        slug: BeehiivPublicationSlugs.ForwardGuidance,
        title: BeehiivPublicationTitles.ForwardGuidance,
    },
};

const beehiivPublicationMap = {
    [BeehiivPublicationIds.Daily]: publicationDataMap.Daily,
    [BeehiivPublicationIds.Weekly]: publicationDataMap.Weekly,
    [BeehiivPublicationIds.ResearchDaily]: publicationDataMap.ResearchDaily,
    [BeehiivPublicationIds.Events]: publicationDataMap.Events,
    [BeehiivPublicationIds.Webinars]: publicationDataMap.Webinars,
    [BeehiivPublicationIds.Empire]: publicationDataMap.Empire,
    [BeehiivPublicationIds.Lightspeed]: publicationDataMap.Lightspeed,
    [BeehiivPublicationIds.ForwardGuidance]: publicationDataMap.ForwardGuidance,
    [BeehiivPublicationSlugs.Daily]: publicationDataMap.Daily,
    [BeehiivPublicationSlugs.Weekly]: publicationDataMap.Weekly,
    [BeehiivPublicationSlugs.ResearchDaily]: publicationDataMap.ResearchDaily,
    [BeehiivPublicationSlugs.Events]: publicationDataMap.Events,
    [BeehiivPublicationSlugs.Webinars]: publicationDataMap.Webinars,
    [BeehiivPublicationSlugs.Empire]: publicationDataMap.Empire,
    [BeehiivPublicationSlugs.Lightspeed]: publicationDataMap.Lightspeed,
    [BeehiivPublicationSlugs.ForwardGuidance]: publicationDataMap.ForwardGuidance,
};

export const getPublicationIdBySlug = (slug: BeehiivPublicationSlugs) =>
    beehiivPublicationMap[slug as keyof typeof beehiivPublicationMap].id;

export const getPublicationSlugById = (id: string) =>
    beehiivPublicationMap[id as keyof typeof beehiivPublicationMap].slug;

export const getPublicationTitleById = (id: string) =>
    beehiivPublicationMap[id as keyof typeof beehiivPublicationMap].title;

export const getPublicationTitleBySlug = (slug: BeehiivPublicationSlugs) =>
    beehiivPublicationMap[slug as keyof typeof beehiivPublicationMap].title;
