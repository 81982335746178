export enum AnalyticsEvent {
    signupStarted = `Auth > Account:Signup > Start`,
    signupContinued = `Auth > Account:Signup > Continue`,
    signupBack = `Auth > Account:Signup > Back`,
    signupViewedSalesCal = `Auth > Account:Signup > Opened Sales Calendar`,
    signupSelectedSalesTime = `Auth > Account:Signup > Selected Sales Meeting Timeslot`,
    signupScheduledSalesMeeting = `Auth > Account:Signup > Scheduled Sales Meeting`,
    signupScheduledSalesMeetingFailed = `Auth > Account:Signup > Scheduled Sales Meeting Failed`,
    signupSelectedPlan = `Auth > Account:Signup > Select Plan`,
    signupPlanPaid = `Auth > Account:Signup > Plan Paid`,
    signupFromUnlockedContent = `Auth > Account:Signup > From Unlocked Content`,
    signupFromDashboardApp = `Auth > Account:Signup > From Dashboard App`,
    successfulSignup = `Auth > Account:Signup`,
    successfulLogin = `Auth > Account:Login`,
    successfulInvitationAccepted = `Auth > Account:Invitation Accepted`,
    clickedNavItem = `Navigation > Click:`,
    clickedSearchBar = `Navigation > Click:Search`,
    clickedFilter = `Filter > Click:`,
    viewedPost = `Post > View:Post`,
    viewedPaywall = `Post > View:Paywall`,
    clickedPostAction = `Post > Click:`,
    viewedFlashnote = 'Flashnote > View:Flashnote',
    clickedSupportedToggle = `Filter > Click:`,
    clickedAssetTabs = `Assets > Click:`,
    clickedAssetAction = `Asset > Click:`,
    walletSignMessageRequest = 'Profile Wallet > Sign Message Request',
    walletSignMessageResponse = 'Profile Wallet > Sign Message Response',
    walletTokenConnected = 'Profile Wallet > Token Conneted',
    assetTableSort = 'Asset Table > Sort',
    assetTableFilter = 'Asset Table > Filter',
    governanceListSort = 'Governance > List Sort',
    governanceListFilter = 'Governance > List:Filter',
    governanceProposalButtonClick = 'Governance > Proposal > Button Click',
    governanceProposalTabSelected = 'Governance > Proposal > Tab Selected',
    pageView = 'Page View',
    assetCoverageRequested = `Asset > Coverage Requested`,
    searchCategoryTyped = 'Search > Category Typed',
    searchCategorySelected = 'Search > Category Selected',
}
