import { tVariants } from '../../../style-system';

const selectStyles = tVariants({
    base: [
        'text-sm leading-6 group',
        'rounded-md flex justify-between items-center bg-surface text-sm text-content w-full gap-2',
        'data-[placeholder]:text-content-deselected outline-none focus',
        'hover:border-surface-interact data-error:border-content-danger data-error:hover:border-content-danger',
        'focus-within:ring-primary-focus focus-within:ring-1',
    ],
    slots: {
        content: 'overflow-hidden bg-surface rounded-md border border-surface-divider',
        scrollDown: 'flex items-center justify-center h-[30px] bg-surface text-content cursor-default',
        scrollUp: 'flex items-center justify-center h-[30px] bg-surface text-content cursor-default',
        selectedItem: 'rounded-md flex items-center gap-2 text-sm text-content-muted',
    },
    defaultVariants: {
        size: 'md',
        variant: 'outline',
    },
    variants: {
        size: {
            sm: {
                base: 'text-xs h-8 px-2 py-0.5 [--icon-size:1.25rem]',
                selectedItem: 'text-xs ',
                content: 'text-xs py-1',
            },
            md: {
                base: 'text-sm h-10 px-2 py-2 [--icon-size:1.5rem]',
                selectedItem: 'text-sm',
                content: 'text-sm',
            },
            lg: {
                base: 'text-md h-12 px-2 py-2 [--icon-size:1.75rem]',
                selectedItem: 'text-md',
                content: 'text-md',
            },
        },
        variant: {
            outline: 'border border-surface-divider',
            ghost: 'border-none',
        },
        fullWidth: {
            true: 'w-full',
        },
    },
});

const selectItemStyles = tVariants({
    base: 'rounded-md flex align-middle items-center gap-2 px-2 py-2 bg-surface select-none data-[disabled]:text-content-muted data-[disabled]:pointer-events-none data-highlighted:outline-none data-highlighted:bg-surface-selected data-highlighted:text-content data-highlighted:cursor-pointer data-checked:bg-surface-muted',
    defaultVariants: {
        size: 'md',
    },
    variants: {
        size: {
            sm: 'text-xs py-[6px]',
            md: 'text-sm',
            lg: 'text-md',
        },
    },
});

export { selectItemStyles, selectStyles };
