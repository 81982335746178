import { useEffect } from 'react';

import { useLocalStorage } from '@blockworks/platform/hooks';
import { useTrack } from '@blockworks/platform/services/analytics';
import { useAuth } from '@blockworks/session/context';
import { INVITE_TOKEN_KEY } from '@blockworks/session/models';

import { AnalyticsEvent } from '@/modules/utils/enums/events-tracking';

export const InviteTokenRemover = () => {
    const user = useAuth().user;
    const track = useTrack();
    const { storedValue: storedInviteToken, clearValue: clearInviteToken } = useLocalStorage(INVITE_TOKEN_KEY);

    useEffect(() => {
        if (storedInviteToken && user?.inviteAccepted) {
            clearInviteToken();
            track(AnalyticsEvent.successfulInvitationAccepted);
        }
    }, [user?.inviteAccepted, storedInviteToken, clearInviteToken, track]);

    return <></>;
};
