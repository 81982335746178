import { tVariants } from '../../../style-system';

const creatableComboboxStyles = tVariants({
    base: 'relative w-full bg-surface rounded-md inline-flex items-center focus-within:text-content text-content-muted focus-within:ring-primary-focus focus-within:ring-1',
    slots: {
        inputWrapper: 'flex items-center justify-between w-full pr-2 gap-1',
        input: 'w-full rounded-md pl-2 pr-0 text-inherit bg-transparent placeholder:overflow-visible placeholder:text-content-deselected border-0 border-transparent outline-none focus:text-content focus:border-transparent focus:ring-0 focus:outline-none [&::-webkit-search-cancel-button]:appearance-none autofill-transparent',
        actionWrapper: 'flex items-center gap-1',
        clearButton: 'flex shrink-0 grow-0 cursor-pointer text-surface-interact hover:text-surface-accent',
        indicator: 'flex shrink-0 grow-0 cursor-pointer text-surface-interact outline-none',
        dropdown:
            'z-10 w-full max-h-[150px] p-1 mt-1 border border-surface-divider rounded-md scroller overflow-x-hidden bg-surface',
        option: 'flex justify-between text-inherit w-full items-center cursor-pointer px-2 py-1 rounded-md hover:bg-surface-muted',
        optionInner: 'w-full flex justify-between items-center',
        optionLabel: 'truncate',
        optionDescription: 'text-right whitespace-nowrap',
        optionCreate: 'rounded-md text-xs cursor-pointer px-4 py-1 bg-surface-muted',
    },
    defaultVariants: {
        variant: 'outline',
        size: 'md',
    },
    variants: {
        variant: {
            outline: 'border border-surface-divider',
            ghost: 'border-none',
        },
        disabled: {
            true: {
                base: 'opacity-40 cursor-default focus-within:ring-0',
                input: 'cursor-default',
                clearButton: 'cursor-default hover:text-surface-interact',
                indicator: 'cursor-default',
            },
        },
        readOnly: {
            true: {
                base: 'opacity-40 cursor-default focus-within:ring-0',
                input: 'cursor-default',
                clearButton: 'cursor-default hover:text-surface-interact',
                indicator: 'cursor-default',
            },
        },
        size: {
            sm: {
                base: 'text-xs h-8',
                indicator: 'w-[.95rem] h-[.95rem]',
                clearButton: 'w-[.95rem] h-[.95rem]',
                option: 'text-xs',
                input: 'leading-5',
            },
            md: {
                base: 'text-sm h-10 [--indicator-size:1rem] [--clear-btn-size:1rem]',
                indicator: 'w-[1rem] h-[1rem]',
                clearButton: 'w-[1rem] h-[1rem]',
                option: 'text-sm',
                input: 'leading-6',
            },
            lg: {
                base: 'text-md h-12',
                indicator: 'w-[1.1rem] h-[1.1rem]',
                clearButton: 'w-[1.1rem] h-[1.1rem]',
                option: 'text-md',
                input: 'leading-7',
            },
        },
        isOpen: {
            false: { dropdown: 'hidden' },
        },
    },
});

export { creatableComboboxStyles };
