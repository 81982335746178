import type { AnyObj, Obj, StringUnion } from '../../../typescript';
import { BASE_URLS, type BaseUrl } from '../base-urls';

const isPlainObject = (obj: any): obj is object => {
    if (typeof obj !== 'object' || obj === null) return false;

    let proto = obj;
    while (Object.getPrototypeOf(proto) !== null) {
        proto = Object.getPrototypeOf(proto);
    }

    return Object.getPrototypeOf(obj) === proto || Object.getPrototypeOf(obj) === null;
};

const isFunction = (fn: any): fn is () => any => fn && {}.toString.call(fn) === '[object Function]';

const stripEmpty = <T>(obj: AnyObj): Obj<T> => {
    if (!isPlainObject(obj)) {
        return obj;
    }
    const copy = { ...obj };
    for (const [k, v] of Object.entries(copy)) {
        if (v === undefined || (typeof v === 'string' && v.length === 0) || (Array.isArray(v) && v.length === 0)) {
            delete copy[k];
        }
    }
    return copy;
};

/**
 * @summary Copied from https://github.com/lodash/lodash/blob/main/src/flow.ts
 *
 * ***This fn was copied from the `lodash` project because nextjs edge does not support all `lodash-es` functions
 * because they are optimized with some API's that aren't available in runtime.***
 *
 * @description Composes a function that returns the result of invoking the given functions
 * with the `this` binding of the created function, where each successive
 * invocation is supplied the return value of the previous;
 * `Array` return values will be spread as multiple args.
 * */
function flow(...funcs: Function[]) {
    const { length } = funcs;
    let i = length;
    while (i--) {
        if (typeof funcs[i] !== 'function') {
            throw new TypeError('Expected a function');
        }
    }
    // eslint-disable-next-line func-names
    return function (this: any, ...args: any[]) {
        let j = 0;
        let result = length ? funcs?.[j]?.apply(this, args) : args[0];
        while (++j < length) {
            /** this is a customization: ensures that an array is spread as multiple args */
            if (Array.isArray(result)) {
                result = result.flat();
                result = funcs?.[j]?.call(this, ...result);
            } else {
                result = funcs?.[j]?.call(this, result);
            }
        }
        return result;
    };
}

const getBaseUrl = (baseUrl: BaseUrl, override?: StringUnion<BaseUrl>) => {
    if (override) {
        return BASE_URLS[override as BaseUrl] || override;
    }
    return BASE_URLS[baseUrl];
};

const makeUnencodedParams = (params?: Obj<string | number>) => {
    if (!params) return '';

    const keys = Object.keys(params);

    if (!keys.length) return '';
    return keys.map(key => `${key}=${params[key]}`).join('&');
};

export { flow, getBaseUrl, isFunction, isPlainObject, makeUnencodedParams, stripEmpty };
