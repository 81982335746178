import { useRouter } from 'next/router';

import { useTrack } from '@blockworks/platform/services/analytics';
import { SignIn, SignInProps } from '@blockworks/session/components';

import { AnalyticsEvent } from '@/modules/utils/enums/events-tracking';
import { AppRoutes } from '@/services/auth/auth.routes';

export const AccountSignInForm = (props: SignInProps) => {
    const router = useRouter();
    const track = useTrack();

    const handleSuccess = async () => {
        track(AnalyticsEvent.successfulLogin, { source: router.asPath });
    };

    return <SignIn forgotPasswordLink={AppRoutes.SIGN_IN_FORGOT.pathname} onSuccess={handleSuccess} {...props} />;
};
