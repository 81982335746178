import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { SessionUser } from 'next-auth';
import { useSession } from 'next-auth/react';

import { useIsomorphicLayoutEffect } from '@blockworks/platform/hooks';
import { mapUserToAnalyticsUser, useIdentify, useReset } from '@blockworks/platform/services/analytics';

import { getAuthRedirect } from '@/services/auth/auth.guard';

const IdentifyUser = () => {
    const router = useRouter();
    const session = useSession();
    const identify = useIdentify();
    const reset = useReset();
    const [identifiedUser, setIdentifiedUser] = useState<SessionUser | null>(null);
    const { data } = session;
    const user = data?.user;

    const startSentryReplay = () => {
        if (!Sentry.isInitialized()) {
            console.warn('Sentry is not initialized.');
            return;
        }

        const replay = Sentry.getReplay();
        if (!replay?.getReplayId()) {
            replay?.start();
        }
    };

    // For analytics
    useEffect(() => {
        const isInternalUser = user?.email?.includes('@blockworks.co') ?? false;
        if (identifiedUser === null && user !== null && user?.id) {
            setIdentifiedUser(user);
            identify?.(String(user.id), mapUserToAnalyticsUser(user));
            Sentry.setUser({
                email: user.email,
                id: user.id,
                username: user.name,
            });

            Sentry.setTag('internal', isInternalUser);
        } else if (identifiedUser !== null && user === null) {
            setIdentifiedUser(null);
            reset?.();
        }

        if (!identifiedUser && !user && !isInternalUser) {
            // Session replay for all non-authenticated users
            startSentryReplay();
        }
    }, [user, identify, reset]);

    useIsomorphicLayoutEffect(() => {
        if (session.status === 'loading') return;

        const [pathname] = router.asPath.split('?');
        const { destination, params } = getAuthRedirect(session?.data?.user!, pathname!) ?? {};

        if (destination && destination !== pathname) {
            router.push({
                pathname: destination,
                query: params,
            });
        }
    }, [session, router]);

    return null;
};

export { IdentifyUser };
