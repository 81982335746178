export type AssetSymbol = keyof typeof logos;

export const logos = {
    $pac: '/images/tokens/$pac.svg',
    btu: '/images/tokens/btu.svg',
    eca: '/images/tokens/eca.svg',
    hush: '/images/tokens/hush.svg',
    mwc: '/images/tokens/mwc.svg',
    rari: '/images/tokens/rari.svg',
    trig: '/images/tokens/trig.svg',
    '0xbtc': '/images/tokens/0xbtc.svg',
    btx: '/images/tokens/btx.svg',
    edg: '/images/tokens/edg.svg',
    hvn: '/images/tokens/hvn.svg',
    mxm: '/images/tokens/mxm.svg',
    ray: '/images/tokens/ray.svg',
    trtl: '/images/tokens/trtl.svg',
    '1inch': '/images/tokens/1inch.svg',
    bu: '/images/tokens/bu.svg',
    edo: '/images/tokens/edo.svg',
    hydro: '/images/tokens/hydro.svg',
    myb: '/images/tokens/myb.svg',
    rbn: '/images/tokens/rbn.svg',
    tru: '/images/tokens/tru.svg',
    '1st': '/images/tokens/1st.svg',
    bunny: '/images/tokens/bunny.svg',
    edoge: '/images/tokens/edoge.svg',
    hyn: '/images/tokens/hyn.svg',
    mzc: '/images/tokens/mzc.svg',
    rcn: '/images/tokens/rcn.svg',
    trx: '/images/tokens/trx.svg',
    '2give': '/images/tokens/2give.svg',
    burst: '/images/tokens/burst.svg',
    efi: '/images/tokens/efi.svg',
    hzn: '/images/tokens/hzn.svg',
    nano: '/images/tokens/nano.svg',
    rdd: '/images/tokens/rdd.svg',
    tryb: '/images/tokens/tryb.svg',
    aave: '/images/tokens/aave.svg',
    busd: '/images/tokens/busd.svg',
    efx: '/images/tokens/efx.svg',
    ibat: '/images/tokens/ibat.svg',
    nas: '/images/tokens/nas.svg',
    rdn: '/images/tokens/rdn.svg',
    ttc: '/images/tokens/ttc.svg',
    abbc: '/images/tokens/abbc.svg',
    bwt: '/images/tokens/bwt.svg',
    egld: '/images/tokens/egld.svg',
    icn: '/images/tokens/icn.svg',
    nav: '/images/tokens/nav.svg',
    ren: '/images/tokens/ren.svg',
    ttt: '/images/tokens/ttt.svg',
    abt: '/images/tokens/abt.svg',
    bz: '/images/tokens/bz.svg',
    egt: '/images/tokens/egt.svg',
    icp: '/images/tokens/icp.svg',
    ncash: '/images/tokens/ncash.svg',
    renbtc: '/images/tokens/renbtc.svg',
    tube: '/images/tokens/tube.svg',
    ac3: '/images/tokens/ac3.svg',
    bze: '/images/tokens/bze.svg',
    ekg: '/images/tokens/ekg.svg',
    icx: '/images/tokens/icx.svg',
    nct: '/images/tokens/nct.svg',
    rep: '/images/tokens/rep.svg',
    tusd: '/images/tokens/tusd.svg',
    act: '/images/tokens/act.svg',
    bznt: '/images/tokens/bznt.svg',
    ekt: '/images/tokens/ekt.svg',
    idai: '/images/tokens/idai.svg',
    ndz: '/images/tokens/ndz.svg',
    repv2: '/images/tokens/repv2.svg',
    twt: '/images/tokens/twt.svg',
    actn: '/images/tokens/actn.svg',
    bzrx: '/images/tokens/bzrx.svg',
    ela: '/images/tokens/ela.svg',
    idex: '/images/tokens/idex.svg',
    near: '/images/tokens/near.svg',
    req: '/images/tokens/req.svg',
    tzc: '/images/tokens/tzc.svg',
    ada: '/images/tokens/ada.svg',
    c20: '/images/tokens/c20.svg',
    elec: '/images/tokens/elec.svg',
    ieth: '/images/tokens/ieth.svg',
    nebl: '/images/tokens/nebl.svg',
    rev: '/images/tokens/rev.svg',
    ubq: '/images/tokens/ubq.svg',
    adb: '/images/tokens/adb.svg',
    c98: '/images/tokens/c98.svg',
    elf: '/images/tokens/elf.svg',
    ignis: '/images/tokens/ignis.svg',
    nec: '/images/tokens/nec.svg',
    rfox: '/images/tokens/rfox.svg',
    ubt: '/images/tokens/ubt.svg',
    add: '/images/tokens/add.svg',
    cake: '/images/tokens/cake.svg',
    elix: '/images/tokens/elix.svg',
    iknc: '/images/tokens/iknc.svg',
    neo: '/images/tokens/neo.svg',
    rhoc: '/images/tokens/rhoc.svg',
    uft: '/images/tokens/uft.svg',
    adx: '/images/tokens/adx.svg',
    call: '/images/tokens/call.svg',
    ella: '/images/tokens/ella.svg',
    ilink: '/images/tokens/ilink.svg',
    neos: '/images/tokens/neos.svg',
    ric: '/images/tokens/ric.svg',
    ult: '/images/tokens/ult.svg',
    ae: '/images/tokens/ae.svg',
    capp: '/images/tokens/capp.svg',
    emb: '/images/tokens/emb.svg',
    ilk: '/images/tokens/ilk.svg',
    neu: '/images/tokens/neu.svg',
    rif: '/images/tokens/rif.svg',
    uma: '/images/tokens/uma.svg',
    aeon: '/images/tokens/aeon.svg',
    car: '/images/tokens/car.svg',
    emc: '/images/tokens/emc.svg',
    imx: '/images/tokens/imx.svg',
    new: '/images/tokens/new.svg',
    rise: '/images/tokens/rise.svg',
    uncx: '/images/tokens/uncx.svg',
    aergo: '/images/tokens/aergo.svg',
    card: '/images/tokens/card.svg',
    emc2: '/images/tokens/emc2.svg',
    inb: '/images/tokens/inb.svg',
    nex: '/images/tokens/nex.svg',
    rlc: '/images/tokens/rlc.svg',
    unfi: '/images/tokens/unfi.svg',
    aeur: '/images/tokens/aeur.svg',
    cbat: '/images/tokens/cbat.svg',
    eng: '/images/tokens/eng.svg',
    inj: '/images/tokens/inj.svg',
    nexo: '/images/tokens/nexo.svg',
    rndr: '/images/tokens/rndr.svg',
    uni: '/images/tokens/uni.svg',
    agi: '/images/tokens/agi.svg',
    cbc: '/images/tokens/cbc.svg',
    enj: '/images/tokens/enj.svg',
    ink: '/images/tokens/ink.svg',
    nexxo: '/images/tokens/nexxo.svg',
    rook: '/images/tokens/rook.svg',
    unity: '/images/tokens/unity.svg',
    agrs: '/images/tokens/agrs.svg',
    cbt: '/images/tokens/cbt.svg',
    ens: '/images/tokens/ens.svg',
    ins: '/images/tokens/ins.svg',
    nft: '/images/tokens/nft.svg',
    rose: '/images/tokens/rose.svg',
    unn: '/images/tokens/unn.svg',
    aion: '/images/tokens/aion.svg',
    cc: '/images/tokens/cc.svg',
    entrp: '/images/tokens/entrp.svg',
    ion: '/images/tokens/ion.svg',
    ngc: '/images/tokens/ngc.svg',
    rpx: '/images/tokens/rpx.svg',
    uos: '/images/tokens/uos.svg',
    ait: '/images/tokens/ait.svg',
    cccx: '/images/tokens/cccx.svg',
    eon: '/images/tokens/eon.svg',
    iop: '/images/tokens/iop.svg',
    nio: '/images/tokens/nio.svg',
    rsr: '/images/tokens/rsr.svg',
    upp: '/images/tokens/upp.svg',
    akro: '/images/tokens/akro.svg',
    cdai: '/images/tokens/cdai.svg',
    eop: '/images/tokens/eop.svg',
    iost: '/images/tokens/iost.svg',
    niox: '/images/tokens/niox.svg',
    rsv: '/images/tokens/rsv.svg',
    usd: '/images/tokens/usd.svg',
    akt: '/images/tokens/akt.svg',
    cdn: '/images/tokens/cdn.svg',
    eos: '/images/tokens/eos.svg',
    iota: '/images/tokens/iota.svg',
    nkn: '/images/tokens/nkn.svg',
    rub: '/images/tokens/rub.svg',
    usdc: '/images/tokens/usdc.svg',
    alcx: '/images/tokens/alcx.svg',
    cdt: '/images/tokens/cdt.svg',
    eosdac: '/images/tokens/eosdac.svg',
    iotx: '/images/tokens/iotx.svg',
    nlc2: '/images/tokens/nlc2.svg',
    rune: '/images/tokens/rune.svg',
    usdn: '/images/tokens/usdn.svg',
    aleph: '/images/tokens/aleph.svg',
    cel: '/images/tokens/cel.svg',
    eqli: '/images/tokens/eqli.svg',
    iq: '/images/tokens/iq.svg',
    nlg: '/images/tokens/nlg.svg',
    rvn: '/images/tokens/rvn.svg',
    usdp: '/images/tokens/usdp.svg',
    algo: '/images/tokens/algo.svg',
    celo: '/images/tokens/celo.svg',
    equa: '/images/tokens/equa.svg',
    irep: '/images/tokens/irep.svg',
    nmc: '/images/tokens/nmc.svg',
    ryo: '/images/tokens/ryo.svg',
    usds: '/images/tokens/usds.svg',
    alis: '/images/tokens/alis.svg',
    celr: '/images/tokens/celr.svg',
    erd: '/images/tokens/erd.svg',
    iris: '/images/tokens/iris.svg',
    nmr: '/images/tokens/nmr.svg',
    s: '/images/tokens/s.svg',
    usdt: '/images/tokens/usdt.svg',
    alpha: '/images/tokens/alpha.svg',
    cennz: '/images/tokens/cennz.svg',
    ern: '/images/tokens/ern.svg',
    isusd: '/images/tokens/isusd.svg',
    noia: '/images/tokens/noia.svg',
    safe: '/images/tokens/safe.svg',
    ust: '/images/tokens/ust.svg',
    alx: '/images/tokens/alx.svg',
    cenz: '/images/tokens/cenz.svg',
    esd: '/images/tokens/esd.svg',
    itc: '/images/tokens/itc.svg',
    nper: '/images/tokens/nper.svg',
    safemoon: '/images/tokens/safemoon.svg',
    utk: '/images/tokens/utk.svg',
    amb: '/images/tokens/amb.svg',
    ceth: '/images/tokens/ceth.svg',
    esp: '/images/tokens/esp.svg',
    iusdc: '/images/tokens/iusdc.svg',
    npxs: '/images/tokens/npxs.svg',
    sai: '/images/tokens/sai.svg',
    uuu: '/images/tokens/uuu.svg',
    amlt: '/images/tokens/amlt.svg',
    cfx: '/images/tokens/cfx.svg',
    ess: '/images/tokens/ess.svg',
    iwbtc: '/images/tokens/iwbtc.svg',
    nrg: '/images/tokens/nrg.svg',
    salt: '/images/tokens/salt.svg',
    value: '/images/tokens/value.svg',
    amp: '/images/tokens/amp.svg',
    chai: '/images/tokens/chai.svg',
    etc: '/images/tokens/etc.svg',
    izrx: '/images/tokens/izrx.svg',
    nrve: '/images/tokens/nrve.svg',
    san: '/images/tokens/san.svg',
    velo: '/images/tokens/velo.svg',
    ampl: '/images/tokens/ampl.svg',
    chain: '/images/tokens/chain.svg',
    eth: '/images/tokens/eth.svg',
    jet: '/images/tokens/jet.svg',
    ntbc: '/images/tokens/ntbc.svg',
    sand: '/images/tokens/sand.svg',
    veri: '/images/tokens/veri.svg',
    anc: '/images/tokens/anc.svg',
    chat: '/images/tokens/chat.svg',
    ethdydx: '/images/tokens/ethdydx.svg',
    jnt: '/images/tokens/jnt.svg',
    nu: '/images/tokens/nu.svg',
    sar: '/images/tokens/sar.svg',
    vest: '/images/tokens/vest.svg',
    anj: '/images/tokens/anj.svg',
    chips: '/images/tokens/chips.svg',
    ethos: '/images/tokens/ethos.svg',
    joe: '/images/tokens/joe.svg',
    nuls: '/images/tokens/nuls.svg',
    sbd: '/images/tokens/sbd.svg',
    vet: '/images/tokens/vet.svg',
    ankr: '/images/tokens/ankr.svg',
    chr: '/images/tokens/chr.svg',
    etn: '/images/tokens/etn.svg',
    jpy: '/images/tokens/jpy.svg',
    nxs: '/images/tokens/nxs.svg',
    sberbank: '/images/tokens/sberbank.svg',
    vgx: '/images/tokens/vgx.svg',
    ant: '/images/tokens/ant.svg',
    chsb: '/images/tokens/chsb.svg',
    etp: '/images/tokens/etp.svg',
    jrt: '/images/tokens/jrt.svg',
    nxt: '/images/tokens/nxt.svg',
    sc: '/images/tokens/sc.svg',
    via: '/images/tokens/via.svg',
    apex: '/images/tokens/apex.svg',
    chz: '/images/tokens/chz.svg',
    etz: '/images/tokens/etz.svg',
    jst: '/images/tokens/jst.svg',
    oag: '/images/tokens/oag.svg',
    scrl: '/images/tokens/scrl.svg',
    vib: '/images/tokens/vib.svg',
    aph: '/images/tokens/aph.svg',
    cix: '/images/tokens/cix.svg',
    eur: '/images/tokens/eur.svg',
    juno: '/images/tokens/juno.svg',
    oax: '/images/tokens/oax.svg',
    scrt: '/images/tokens/scrt.svg',
    vibe: '/images/tokens/vibe.svg',
    apl: '/images/tokens/apl.svg',
    ckb: '/images/tokens/ckb.svg',
    evx: '/images/tokens/evx.svg',
    kava: '/images/tokens/kava.svg',
    ocean: '/images/tokens/ocean.svg',
    sdt: '/images/tokens/sdt.svg',
    vidt: '/images/tokens/vidt.svg',
    appc: '/images/tokens/appc.svg',
    clam: '/images/tokens/clam.svg',
    ewt: '/images/tokens/ewt.svg',
    kcs: '/images/tokens/kcs.svg',
    ocn: '/images/tokens/ocn.svg',
    seele: '/images/tokens/seele.svg',
    vikky: '/images/tokens/vikky.svg',
    apw: '/images/tokens/apw.svg',
    clo: '/images/tokens/clo.svg',
    exmo: '/images/tokens/exmo.svg',
    kda: '/images/tokens/kda.svg',
    ode: '/images/tokens/ode.svg',
    sefi: '/images/tokens/sefi.svg',
    vin: '/images/tokens/vin.svg',
    apy: '/images/tokens/apy.svg',
    cloak: '/images/tokens/cloak.svg',
    exp: '/images/tokens/exp.svg',
    keep: '/images/tokens/keep.svg',
    ogn: '/images/tokens/ogn.svg',
    sem: '/images/tokens/sem.svg',
    vite: '/images/tokens/vite.svg',
    ar: '/images/tokens/ar.svg',
    clout: '/images/tokens/clout.svg',
    exrn: '/images/tokens/exrn.svg',
    key: '/images/tokens/key.svg',
    ohm: '/images/tokens/ohm.svg',
    sfi: '/images/tokens/sfi.svg',
    viu: '/images/tokens/viu.svg',
    arb: '/images/tokens/arb.svg',
    cmm: '/images/tokens/cmm.svg',
    exy: '/images/tokens/exy.svg',
    kick: '/images/tokens/kick.svg',
    ok: '/images/tokens/ok.svg',
    sfp: '/images/tokens/sfp.svg',
    vivo: '/images/tokens/vivo.svg',
    ardr: '/images/tokens/ardr.svg',
    cmt: '/images/tokens/cmt.svg',
    fab: '/images/tokens/fab.svg',
    kin: '/images/tokens/kin.svg',
    okb: '/images/tokens/okb.svg',
    shib: '/images/tokens/shib.svg',
    vlx: '/images/tokens/vlx.svg',
    arg: '/images/tokens/arg.svg',
    cnd: '/images/tokens/cnd.svg',
    fair: '/images/tokens/fair.svg',
    klay: '/images/tokens/klay.svg',
    okt: '/images/tokens/okt.svg',
    shift: '/images/tokens/shift.svg',
    vrc: '/images/tokens/vrc.svg',
    ark: '/images/tokens/ark.svg',
    cnx: '/images/tokens/cnx.svg',
    fct: '/images/tokens/fct.svg',
    klown: '/images/tokens/klown.svg',
    olt: '/images/tokens/olt.svg',
    shr: '/images/tokens/shr.svg',
    vrs: '/images/tokens/vrs.svg',
    armor: '/images/tokens/armor.svg',
    cny: '/images/tokens/cny.svg',
    feed: '/images/tokens/feed.svg',
    klv: '/images/tokens/klv.svg',
    omg: '/images/tokens/omg.svg',
    shuf: '/images/tokens/shuf.svg',
    vrsc: '/images/tokens/vrsc.svg',
    arn: '/images/tokens/arn.svg',
    cob: '/images/tokens/cob.svg',
    fei: '/images/tokens/fei.svg',
    kmd: '/images/tokens/kmd.svg',
    omni: '/images/tokens/omni.svg',
    sia: '/images/tokens/sia.svg',
    vsp: '/images/tokens/vsp.svg',
    arnx: '/images/tokens/arnx.svg',
    colx: '/images/tokens/colx.svg',
    fet: '/images/tokens/fet.svg',
    knc: '/images/tokens/knc.svg',
    one: '/images/tokens/one.svg',
    sib: '/images/tokens/sib.svg',
    vsys: '/images/tokens/vsys.svg',
    aro: '/images/tokens/aro.svg',
    comp: '/images/tokens/comp.svg',
    fida: '/images/tokens/fida.svg',
    krb: '/images/tokens/krb.svg',
    ong: '/images/tokens/ong.svg',
    sin: '/images/tokens/sin.svg',
    vtc: '/images/tokens/vtc.svg',
    arrr: '/images/tokens/arrr.svg',
    coni: '/images/tokens/coni.svg',
    fil: '/images/tokens/fil.svg',
    ksm: '/images/tokens/ksm.svg',
    onion: '/images/tokens/onion.svg',
    skl: '/images/tokens/skl.svg',
    vtho: '/images/tokens/vtho.svg',
    ary: '/images/tokens/ary.svg',
    coqui: '/images/tokens/coqui.svg',
    filda: '/images/tokens/filda.svg',
    kyl: '/images/tokens/kyl.svg',
    ont: '/images/tokens/ont.svg',
    sky: '/images/tokens/sky.svg',
    wabi: '/images/tokens/wabi.svg',
    ast: '/images/tokens/ast.svg',
    cosm: '/images/tokens/cosm.svg',
    fjc: '/images/tokens/fjc.svg',
    lamb: '/images/tokens/lamb.svg',
    oot: '/images/tokens/oot.svg',
    slr: '/images/tokens/slr.svg',
    wan: '/images/tokens/wan.svg',
    astro: '/images/tokens/astro.svg',
    coti: '/images/tokens/coti.svg',
    fldc: '/images/tokens/fldc.svg',
    land: '/images/tokens/land.svg',
    op: '/images/tokens/op.svg',
    sls: '/images/tokens/sls.svg',
    waves: '/images/tokens/waves.svg',
    atm: '/images/tokens/atm.svg',
    cov: '/images/tokens/cov.svg',
    flo: '/images/tokens/flo.svg',
    lba: '/images/tokens/lba.svg',
    open: '/images/tokens/open.svg',
    slt: '/images/tokens/slt.svg',
    wax: '/images/tokens/wax.svg',
    atmi: '/images/tokens/atmi.svg',
    cova: '/images/tokens/cova.svg',
    flow: '/images/tokens/flow.svg',
    lbc: '/images/tokens/lbc.svg',
    opium: '/images/tokens/opium.svg',
    smart: '/images/tokens/smart.svg',
    waxp: '/images/tokens/waxp.svg',
    atom: '/images/tokens/atom.svg',
    cover: '/images/tokens/cover.svg',
    flux: '/images/tokens/flux.svg',
    lcx: '/images/tokens/lcx.svg',
    orbs: '/images/tokens/orbs.svg',
    snc: '/images/tokens/snc.svg',
    wbtc: '/images/tokens/wbtc.svg',
    auc: '/images/tokens/auc.svg',
    cpc: '/images/tokens/cpc.svg',
    fota: '/images/tokens/fota.svg',
    ldo: '/images/tokens/ldo.svg',
    orc: '/images/tokens/orc.svg',
    sngls: '/images/tokens/sngls.svg',
    wct: '/images/tokens/wct.svg',
    audio: '/images/tokens/audio.svg',
    cpx: '/images/tokens/cpx.svg',
    fox: '/images/tokens/fox.svg',
    lend: '/images/tokens/lend.svg',
    orn: '/images/tokens/orn.svg',
    snm: '/images/tokens/snm.svg',
    wexpoly: '/images/tokens/wexpoly.svg',
    audr: '/images/tokens/audr.svg',
    cre: '/images/tokens/cre.svg',
    frax: '/images/tokens/frax.svg',
    leo: '/images/tokens/leo.svg',
    osmo: '/images/tokens/osmo.svg',
    snt: '/images/tokens/snt.svg',
    wgr: '/images/tokens/wgr.svg',
    aura: '/images/tokens/aura.svg',
    cream: '/images/tokens/cream.svg',
    frm: '/images/tokens/frm.svg',
    link: '/images/tokens/link.svg',
    ost: '/images/tokens/ost.svg',
    snx: '/images/tokens/snx.svg',
    whale: '/images/tokens/whale.svg',
    auto: '/images/tokens/auto.svg',
    cred: '/images/tokens/cred.svg',
    front: '/images/tokens/front.svg',
    lit: '/images/tokens/lit.svg',
    ovc: '/images/tokens/ovc.svg',
    soc: '/images/tokens/soc.svg',
    wib: '/images/tokens/wib.svg',
    avax: '/images/tokens/avax.svg',
    crep: '/images/tokens/crep.svg',
    fsn: '/images/tokens/fsn.svg',
    lkk: '/images/tokens/lkk.svg',
    ox: '/images/tokens/ox.svg',
    sol: '/images/tokens/sol.svg',
    wicc: '/images/tokens/wicc.svg',
    awc: '/images/tokens/awc.svg',
    cro: '/images/tokens/cro.svg',
    ft: '/images/tokens/ft.svg',
    lky: '/images/tokens/lky.svg',
    oxt: '/images/tokens/oxt.svg',
    soul: '/images/tokens/soul.svg',
    wing: '/images/tokens/wing.svg',
    axp: '/images/tokens/axp.svg',
    crpt: '/images/tokens/crpt.svg',
    ftc: '/images/tokens/ftc.svg',
    ln: '/images/tokens/ln.svg',
    pBTC: '/images/tokens/pBTC.svg',
    sov: '/images/tokens/sov.svg',
    wings: '/images/tokens/wings.svg',
    axs: '/images/tokens/axs.svg',
    crv: '/images/tokens/crv.svg',
    ftm: '/images/tokens/ftm.svg',
    loki: '/images/tokens/loki.svg',
    pai: '/images/tokens/pai.svg',
    spacehbit: '/images/tokens/spacehbit.svg',
    wld: '/images/tokens/wld.svg',
    aywa: '/images/tokens/aywa.svg',
    crw: '/images/tokens/crw.svg',
    ftt: '/images/tokens/ftt.svg',
    lon: '/images/tokens/lon.svg',
    paint: '/images/tokens/paint.svg',
    spank: '/images/tokens/spank.svg',
    woo: '/images/tokens/woo.svg',
    bab: '/images/tokens/bab.svg',
    cs: '/images/tokens/cs.svg',
    fuel: '/images/tokens/fuel.svg',
    looks: '/images/tokens/looks.svg',
    pal: '/images/tokens/pal.svg',
    spell: '/images/tokens/spell.svg',
    wpr: '/images/tokens/wpr.svg',
    bac: '/images/tokens/bac.svg',
    csai: '/images/tokens/csai.svg',
    fun: '/images/tokens/fun.svg',
    loom: '/images/tokens/loom.svg',
    par: '/images/tokens/par.svg',
    sphtx: '/images/tokens/sphtx.svg',
    wrc: '/images/tokens/wrc.svg',
    bal: '/images/tokens/bal.svg',
    csc: '/images/tokens/csc.svg',
    fxc: '/images/tokens/fxc.svg',
    lpt: '/images/tokens/lpt.svg',
    part: '/images/tokens/part.svg',
    spn: '/images/tokens/spn.svg',
    wrx: '/images/tokens/wrx.svg',
    bam: '/images/tokens/bam.svg',
    cspr: '/images/tokens/cspr.svg',
    fxs: '/images/tokens/fxs.svg',
    lqd: '/images/tokens/lqd.svg',
    pasc: '/images/tokens/pasc.svg',
    srm: '/images/tokens/srm.svg',
    wtc: '/images/tokens/wtc.svg',
    band: '/images/tokens/band.svg',
    ctc: '/images/tokens/ctc.svg',
    fxt: '/images/tokens/fxt.svg',
    lqty: '/images/tokens/lqty.svg',
    pasl: '/images/tokens/pasl.svg',
    srn: '/images/tokens/srn.svg',
    wxt: '/images/tokens/wxt.svg',
    bao: '/images/tokens/bao.svg',
    ctr: '/images/tokens/ctr.svg',
    gala: '/images/tokens/gala.svg',
    lrc: '/images/tokens/lrc.svg',
    pax: '/images/tokens/pax.svg',
    stak: '/images/tokens/stak.svg',
    x: '/images/tokens/x.svg',
    base: '/images/tokens/base.svg',
    ctxc: '/images/tokens/ctxc.svg',
    game: '/images/tokens/game.svg',
    lsk: '/images/tokens/lsk.svg',
    paxg: '/images/tokens/paxg.svg',
    stake: '/images/tokens/stake.svg',
    xas: '/images/tokens/xas.svg',
    bat: '/images/tokens/bat.svg',
    cube: '/images/tokens/cube.svg',
    gas: '/images/tokens/gas.svg',
    ltc: '/images/tokens/ltc.svg',
    pay: '/images/tokens/pay.svg',
    start: '/images/tokens/start.svg',
    xbc: '/images/tokens/xbc.svg',
    bay: '/images/tokens/bay.svg',
    cusdc: '/images/tokens/cusdc.svg',
    gbp: '/images/tokens/gbp.svg',
    lto: '/images/tokens/lto.svg',
    payx: '/images/tokens/payx.svg',
    steem: '/images/tokens/steem.svg',
    xbp: '/images/tokens/xbp.svg',
    bbr: '/images/tokens/bbr.svg',
    cv: '/images/tokens/cv.svg',
    gbx: '/images/tokens/gbx.svg',
    lun: '/images/tokens/lun.svg',
    pbr: '/images/tokens/pbr.svg',
    step: '/images/tokens/step.svg',
    xby: '/images/tokens/xby.svg',
    bcbc: '/images/tokens/bcbc.svg',
    cvc: '/images/tokens/cvc.svg',
    gbyte: '/images/tokens/gbyte.svg',
    luna: '/images/tokens/luna.svg',
    pendle: '/images/tokens/pendle.svg',
    steth: '/images/tokens/steth.svg',
    xch: '/images/tokens/xch.svg',
    bcc: '/images/tokens/bcc.svg',
    cvp: '/images/tokens/cvp.svg',
    gdc: '/images/tokens/gdc.svg',
    lunc: '/images/tokens/lunc.svg',
    perl: '/images/tokens/perl.svg',
    stg: '/images/tokens/stg.svg',
    xchf: '/images/tokens/xchf.svg',
    bcd: '/images/tokens/bcd.svg',
    cvt: '/images/tokens/cvt.svg',
    gem: '/images/tokens/gem.svg',
    lxt: '/images/tokens/lxt.svg',
    perp: '/images/tokens/perp.svg',
    stmx: '/images/tokens/stmx.svg',
    xcp: '/images/tokens/xcp.svg',
    bch: '/images/tokens/bch.svg',
    cvx: '/images/tokens/cvx.svg',
    gen: '/images/tokens/gen.svg',
    lym: '/images/tokens/lym.svg',
    pickle: '/images/tokens/pickle.svg',
    storj: '/images/tokens/storj.svg',
    xdb: '/images/tokens/xdb.svg',
    bcha: '/images/tokens/bcha.svg',
    cwbtc: '/images/tokens/cwbtc.svg',
    generic: '/images/tokens/generic.svg',
    maha: '/images/tokens/maha.svg',
    pink: '/images/tokens/pink.svg',
    storm: '/images/tokens/storm.svg',
    xdce: '/images/tokens/xdce.svg',
    bcio: '/images/tokens/bcio.svg',
    czrx: '/images/tokens/czrx.svg',
    gin: '/images/tokens/gin.svg',
    maid: '/images/tokens/maid.svg',
    pirl: '/images/tokens/pirl.svg',
    stox: '/images/tokens/stox.svg',
    xdn: '/images/tokens/xdn.svg',
    bcn: '/images/tokens/bcn.svg',
    d: '/images/tokens/d.svg',
    glxt: '/images/tokens/glxt.svg',
    man: '/images/tokens/man.svg',
    pivx: '/images/tokens/pivx.svg',
    stpt: '/images/tokens/stpt.svg',
    xem: '/images/tokens/xem.svg',
    bco: '/images/tokens/bco.svg',
    dadi: '/images/tokens/dadi.svg',
    gmr: '/images/tokens/gmr.svg',
    'mana-old': '/images/tokens/mana-old.svg',
    plDAI: '/images/tokens/plDAI.svg',
    stq: '/images/tokens/stq.svg',
    xhv: '/images/tokens/xhv.svg',
    bcpt: '/images/tokens/bcpt.svg',
    dafi: '/images/tokens/dafi.svg',
    gno: '/images/tokens/gno.svg',
    mana: '/images/tokens/mana.svg',
    plUSDC: '/images/tokens/plUSDC.svg',
    strat: '/images/tokens/strat.svg',
    xin: '/images/tokens/xin.svg',
    bdl: '/images/tokens/bdl.svg',
    dag: '/images/tokens/dag.svg',
    gns: '/images/tokens/gns.svg',
    mars: '/images/tokens/mars.svg',
    play: '/images/tokens/play.svg',
    stx: '/images/tokens/stx.svg',
    xlm: '/images/tokens/xlm.svg',
    beam: '/images/tokens/beam.svg',
    dai: '/images/tokens/dai.svg',
    gnt: '/images/tokens/gnt.svg',
    math: '/images/tokens/math.svg',
    plr: '/images/tokens/plr.svg',
    sub: '/images/tokens/sub.svg',
    xlq: '/images/tokens/xlq.svg',
    bel: '/images/tokens/bel.svg',
    dasc: '/images/tokens/dasc.svg',
    gnx: '/images/tokens/gnx.svg',
    matic: '/images/tokens/matic.svg',
    png: '/images/tokens/png.svg',
    sumo: '/images/tokens/sumo.svg',
    xmark: '/images/tokens/xmark.svg',
    bela: '/images/tokens/bela.svg',
    dash: '/images/tokens/dash.svg',
    go: '/images/tokens/go.svg',
    matter: '/images/tokens/matter.svg',
    pnk: '/images/tokens/pnk.svg',
    super: '/images/tokens/super.svg',
    xmcc: '/images/tokens/xmcc.svg',
    beta: '/images/tokens/beta.svg',
    dat: '/images/tokens/dat.svg',
    goc: '/images/tokens/goc.svg',
    max: '/images/tokens/max.svg',
    pnt: '/images/tokens/pnt.svg',
    suqa: '/images/tokens/suqa.svg',
    xmg: '/images/tokens/xmg.svg',
    bf: '/images/tokens/bf.svg',
    data: '/images/tokens/data.svg',
    gold: '/images/tokens/gold.svg',
    mbc: '/images/tokens/mbc.svg',
    poa: '/images/tokens/poa.svg',
    sushi: '/images/tokens/sushi.svg',
    xmo: '/images/tokens/xmo.svg',
    bifi: '/images/tokens/bifi.svg',
    datx: '/images/tokens/datx.svg',
    got: '/images/tokens/got.svg',
    mcap: '/images/tokens/mcap.svg',
    poe: '/images/tokens/poe.svg',
    suter: '/images/tokens/suter.svg',
    xmr: '/images/tokens/xmr.svg',
    bix: '/images/tokens/bix.svg',
    dbc: '/images/tokens/dbc.svg',
    grc: '/images/tokens/grc.svg',
    mco: '/images/tokens/mco.svg',
    pokt: '/images/tokens/pokt.svg',
    swap: '/images/tokens/swap.svg',
    xmx: '/images/tokens/xmx.svg',
    blcn: '/images/tokens/blcn.svg',
    dcc: '/images/tokens/dcc.svg',
    grin: '/images/tokens/grin.svg',
    mcx: '/images/tokens/mcx.svg',
    polis: '/images/tokens/polis.svg',
    swth: '/images/tokens/swth.svg',
    xmy: '/images/tokens/xmy.svg',
    blk: '/images/tokens/blk.svg',
    dcn: '/images/tokens/dcn.svg',
    grs: '/images/tokens/grs.svg',
    mda: '/images/tokens/mda.svg',
    pols: '/images/tokens/pols.svg',
    sxdt: '/images/tokens/sxdt.svg',
    xnk: '/images/tokens/xnk.svg',
    block: '/images/tokens/block.svg',
    dcr: '/images/tokens/dcr.svg',
    grt: '/images/tokens/grt.svg',
    mds: '/images/tokens/mds.svg',
    poly: '/images/tokens/poly.svg',
    sxp: '/images/tokens/sxp.svg',
    xns: '/images/tokens/xns.svg',
    blt: '/images/tokens/blt.svg',
    dct: '/images/tokens/dct.svg',
    gsc: '/images/tokens/gsc.svg',
    med: '/images/tokens/med.svg',
    pond: '/images/tokens/pond.svg',
    syn: '/images/tokens/syn.svg',
    xor: '/images/tokens/xor.svg',
    blue: '/images/tokens/blue.svg',
    ddd: '/images/tokens/ddd.svg',
    gswap: '/images/tokens/gswap.svg',
    medx: '/images/tokens/medx.svg',
    pool: '/images/tokens/pool.svg',
    sys: '/images/tokens/sys.svg',
    xp: '/images/tokens/xp.svg',
    blz: '/images/tokens/blz.svg',
    deez: '/images/tokens/deez.svg',
    gt: '/images/tokens/gt.svg',
    meetone: '/images/tokens/meetone.svg',
    pot: '/images/tokens/pot.svg',
    taas: '/images/tokens/taas.svg',
    xpa: '/images/tokens/xpa.svg',
    bnb: '/images/tokens/bnb.svg',
    dent: '/images/tokens/dent.svg',
    gtc: '/images/tokens/gtc.svg',
    met: '/images/tokens/met.svg',
    powr: '/images/tokens/powr.svg',
    tau: '/images/tokens/tau.svg',
    xpm: '/images/tokens/xpm.svg',
    bnt: '/images/tokens/bnt.svg',
    deri: '/images/tokens/deri.svg',
    gto: '/images/tokens/gto.svg',
    mfg: '/images/tokens/mfg.svg',
    ppay: '/images/tokens/ppay.svg',
    tbtc: '/images/tokens/tbtc.svg',
    xpr: '/images/tokens/xpr.svg',
    bnty: '/images/tokens/bnty.svg',
    dew: '/images/tokens/dew.svg',
    gup: '/images/tokens/gup.svg',
    mft: '/images/tokens/mft.svg',
    ppc: '/images/tokens/ppc.svg',
    tbx: '/images/tokens/tbx.svg',
    xrd: '/images/tokens/xrd.svg',
    bond: '/images/tokens/bond.svg',
    dfi: '/images/tokens/dfi.svg',
    gusd: '/images/tokens/gusd.svg',
    mim: '/images/tokens/mim.svg',
    ppp: '/images/tokens/ppp.svg',
    tct: '/images/tokens/tct.svg',
    xrp: '/images/tokens/xrp.svg',
    bondly: '/images/tokens/bondly.svg',
    dft: '/images/tokens/dft.svg',
    gvt: '/images/tokens/gvt.svg',
    mina: '/images/tokens/mina.svg',
    ppt: '/images/tokens/ppt.svg',
    tel: '/images/tokens/tel.svg',
    xsg: '/images/tokens/xsg.svg',
    booty: '/images/tokens/booty.svg',
    dfyn: '/images/tokens/dfyn.svg',
    gxs: '/images/tokens/gxs.svg',
    miota: '/images/tokens/miota.svg',
    pre: '/images/tokens/pre.svg',
    ten: '/images/tokens/ten.svg',
    xsn: '/images/tokens/xsn.svg',
    bora: '/images/tokens/bora.svg',
    dgb: '/images/tokens/dgb.svg',
    gzr: '/images/tokens/gzr.svg',
    mir: '/images/tokens/mir.svg',
    premia: '/images/tokens/premia.svg',
    tern: '/images/tokens/tern.svg',
    xsr: '/images/tokens/xsr.svg',
    bos: '/images/tokens/bos.svg',
    dgd: '/images/tokens/dgd.svg',
    hakka: '/images/tokens/hakka.svg',
    mith: '/images/tokens/mith.svg',
    prl: '/images/tokens/prl.svg',
    tfuel: '/images/tokens/tfuel.svg',
    xtz: '/images/tokens/xtz.svg',
    box: '/images/tokens/box.svg',
    dgtx: '/images/tokens/dgtx.svg',
    hav: '/images/tokens/hav.svg',
    mkr: '/images/tokens/mkr.svg',
    pro: '/images/tokens/pro.svg',
    tgch: '/images/tokens/tgch.svg',
    xuc: '/images/tokens/xuc.svg',
    bpt: '/images/tokens/bpt.svg',
    divi: '/images/tokens/divi.svg',
    hbar: '/images/tokens/hbar.svg',
    mln: '/images/tokens/mln.svg',
    pros: '/images/tokens/pros.svg',
    thc: '/images/tokens/thc.svg',
    xvc: '/images/tokens/xvc.svg',
    bq: '/images/tokens/bq.svg',
    dlt: '/images/tokens/dlt.svg',
    hc: '/images/tokens/hc.svg',
    mngo: '/images/tokens/mngo.svg',
    prq: '/images/tokens/prq.svg',
    theta: '/images/tokens/theta.svg',
    xvg: '/images/tokens/xvg.svg',
    bqx: '/images/tokens/bqx.svg',
    dmt: '/images/tokens/dmt.svg',
    hedg: '/images/tokens/hedg.svg',
    mnx: '/images/tokens/mnx.svg',
    pst: '/images/tokens/pst.svg',
    thr: '/images/tokens/thr.svg',
    xyo: '/images/tokens/xyo.svg',
    brd: '/images/tokens/brd.svg',
    dnt: '/images/tokens/dnt.svg',
    her: '/images/tokens/her.svg',
    mnz: '/images/tokens/mnz.svg',
    pungo: '/images/tokens/pungo.svg',
    tio: '/images/tokens/tio.svg',
    xzc: '/images/tokens/xzc.svg',
    bsd: '/images/tokens/bsd.svg',
    dock: '/images/tokens/dock.svg',
    hex: '/images/tokens/hex.svg',
    moac: '/images/tokens/moac.svg',
    pura: '/images/tokens/pura.svg',
    titan: '/images/tokens/titan.svg',
    yfi: '/images/tokens/yfi.svg',
    bsv: '/images/tokens/bsv.svg',
    dodo: '/images/tokens/dodo.svg',
    high: '/images/tokens/high.svg',
    mob: '/images/tokens/mob.svg',
    qash: '/images/tokens/qash.svg',
    tix: '/images/tokens/tix.svg',
    yoyo: '/images/tokens/yoyo.svg',
    'btc++': '/images/tokens/btc++.svg',
    doge: '/images/tokens/doge.svg',
    hight: '/images/tokens/hight.svg',
    mod: '/images/tokens/mod.svg',
    qbit: '/images/tokens/qbit.svg',
    tkn: '/images/tokens/tkn.svg',
    yoyow: '/images/tokens/yoyow.svg',
    btc: '/images/tokens/btc.svg',
    dor: '/images/tokens/dor.svg',
    hive: '/images/tokens/hive.svg',
    mona: '/images/tokens/mona.svg',
    qi: '/images/tokens/qi.svg',
    tks: '/images/tokens/tks.svg',
    zai: '/images/tokens/zai.svg',
    btcd: '/images/tokens/btcd.svg',
    dot: '/images/tokens/dot.svg',
    hns: '/images/tokens/hns.svg',
    mot: '/images/tokens/mot.svg',
    qiwi: '/images/tokens/qiwi.svg',
    tky: '/images/tokens/tky.svg',
    zb: '/images/tokens/zb.svg',
    btch: '/images/tokens/btch.svg',
    drgn: '/images/tokens/drgn.svg',
    hnt: '/images/tokens/hnt.svg',
    mpl: '/images/tokens/mpl.svg',
    qkc: '/images/tokens/qkc.svg',
    tlos: '/images/tokens/tlos.svg',
    zcl: '/images/tokens/zcl.svg',
    btcp: '/images/tokens/btcp.svg',
    drop: '/images/tokens/drop.svg',
    hodl: '/images/tokens/hodl.svg',
    msr: '/images/tokens/msr.svg',
    qlc: '/images/tokens/qlc.svg',
    tnb: '/images/tokens/tnb.svg',
    zco: '/images/tokens/zco.svg',
    btcz: '/images/tokens/btcz.svg',
    dta: '/images/tokens/dta.svg',
    'hot-x': '/images/tokens/hot-x.svg',
    mta: '/images/tokens/mta.svg',
    qnt: '/images/tokens/qnt.svg',
    tnc: '/images/tokens/tnc.svg',
    zec: '/images/tokens/zec.svg',
    btdx: '/images/tokens/btdx.svg',
    dth: '/images/tokens/dth.svg',
    hot: '/images/tokens/hot.svg',
    mth: '/images/tokens/mth.svg',
    qrl: '/images/tokens/qrl.svg',
    tnt: '/images/tokens/tnt.svg',
    zel: '/images/tokens/zel.svg',
    btg: '/images/tokens/btg.svg',
    dtr: '/images/tokens/dtr.svg',
    hpb: '/images/tokens/hpb.svg',
    mtl: '/images/tokens/mtl.svg',
    qsp: '/images/tokens/qsp.svg',
    toke: '/images/tokens/toke.svg',
    zen: '/images/tokens/zen.svg',
    btm: '/images/tokens/btm.svg',
    dtx: '/images/tokens/dtx.svg',
    hsr: '/images/tokens/hsr.svg',
    mtn: '/images/tokens/mtn.svg',
    qtum: '/images/tokens/qtum.svg',
    tomo: '/images/tokens/tomo.svg',
    zest: '/images/tokens/zest.svg',
    btmx: '/images/tokens/btmx.svg',
    dvf: '/images/tokens/dvf.svg',
    ht: '/images/tokens/ht.svg',
    music: '/images/tokens/music.svg',
    quick: '/images/tokens/quick.svg',
    torn: '/images/tokens/torn.svg',
    zil: '/images/tokens/zil.svg',
    bto: '/images/tokens/bto.svg',
    dxd: '/images/tokens/dxd.svg',
    html: '/images/tokens/html.svg',
    mvc: '/images/tokens/mvc.svg',
    r: '/images/tokens/r.svg',
    tpay: '/images/tokens/tpay.svg',
    zilla: '/images/tokens/zilla.svg',
    btrst: '/images/tokens/btrst.svg',
    dxt: '/images/tokens/dxt.svg',
    huc: '/images/tokens/huc.svg',
    mvl: '/images/tokens/mvl.svg',
    rads: '/images/tokens/rads.svg',
    trac: '/images/tokens/trac.svg',
    zks: '/images/tokens/zks.svg',
    bts: '/images/tokens/bts.svg',
    dydx: '/images/tokens/dydx.svg',
    hum: '/images/tokens/hum.svg',
    mvp: '/images/tokens/mvp.svg',
    rae: '/images/tokens/rae.svg',
    trb: '/images/tokens/trb.svg',
    zrx: '/images/tokens/zrx.svg',
    btt: '/images/tokens/btt.svg',
    ebst: '/images/tokens/ebst.svg',
    husd: '/images/tokens/husd.svg',
    mwat: '/images/tokens/mwat.svg',
    rap: '/images/tokens/rap.svg',
    tribe: '/images/tokens/tribe.svg',
};

export const logoImages = {
    zet: 'https://v5.airtableusercontent.com/v1/21/21/1697515200000/P-Z1ofgVmQy2ID1q1G6jog/LIkg9GQT7B_Eo9o_WLCUJtmHcdYYshED5f5Nxvimfe4YMyGC6jvoPqdsxaNDtvj0_AIkNP9yu11cSuKpieCWeg/KQY--n4DaH0EDeuvXK8rvnSbRtx-Np0cN94ps9cHSSY',
    arch: 'https://v5.airtableusercontent.com/v1/21/21/1697515200000/bwiOeOvho-cV3XNDuzUwhg/v5YHg9_I104Xd6Slb4IJaeY8YbTogwYY-3qGVAcExynTX0753LYrcjoAiCCWo4pFvnT2GKtgK9AdVggthmE-5A/cCxf9VuluTlzBRSn8LiqWfJxRNCemiDZbxEgztm7JQI',
    neon: 'https://v5.airtableusercontent.com/v1/21/21/1697515200000/xm3qGPtUfX9wbOPn8a-rDw/Wy3bgREwQQs9EHDVKEbhpfpgWnB21QffRx4daP15Hc9j8wMfXn6DurhLgIf0oABhP2blThcVg9S6-gJCUH5d0Q/b4cEV-7acu2RumdQoJSer6Tmk-K1smO6_N0bA45khzc',
    berachain:
        'https://v5.airtableusercontent.com/v1/21/21/1697515200000/zNc8xlo6GpJLJjdnuAsq0A/k097p3OYwamHBNIlOJiJsNaCiFYRo_VAaA1zTiI8jLuY5SLKyTWmvH0pYK8XMtYS5Kvyj7eet7bjeMuI6GNxvQ/DLmmScHjB74n_8bvJjJwmFM2aQWpfOHypC3rWXCqI0c',
    celestia:
        'https://v5.airtableusercontent.com/v1/21/21/1697515200000/KSlWGs_nUe_omFUKQiJ3_g/Z9B4itHVFR1O-FoduOD4aU8YTRsSQxlq-J_dEyaTGbS8q_Qw6ppyB4DapK1uDlscLi5E6v1Q2UQ0pn2MRWEBqg/cU_RJlfTYqlX84ZRHO4KJS0vdf1-Mh4x2i2KG-sI4Ig',
    zk: 'https://v5.airtableusercontent.com/v1/21/21/1697515200000/OWNFfY2M2NFR41uAp0h6CA/s9wCtUW4xwVcuav37l-njd8eQoPtJKP8zN9t7ExvxtVa93tvoyRZHYDIMWiPoyFx2J9eu1zSn6zCuqUImUVeFg/Anrb_y9W8_Z__8OOhP6g4P1OKF9Qs9xBe8Dvwm3yUwA',
    arbitrum:
        'https://v5.airtableusercontent.com/v1/21/21/1697515200000/_qI7EzGXmuQc90PKCutCcw/s2JGUDaR68qMt2CN6F85R2M8OIMag4R6uDs5jh5xb2xLNc6YwROgJf9IlsbBYpiiOFFY8wW1VDyWipKyyMxARQ/vVUDDiGSCn6njUk0TKQCcaxDYF_UXNZQScu_a7j-Czc',
    rsk: 'https://v5.airtableusercontent.com/v1/21/21/1697515200000/lIMFObHd-AKnL_N31sjipA/WTC8U4h-zrGRiObWrYYww_0uhen22OTJwoYhMpZCUUvCBl51HTXMoZS2JDkeoDTdmaLfi6DaVPHENXrnjLAr-A/7Mpoq4UHmVzwU4lhshwpctesQ8nH-DUPNS4bEz0jqs4',
    arcana: 'https://v5.airtableusercontent.com/v1/21/21/1697515200000/9KiuF2k1r6v5RH0rssiGJQ/4Cgl4MA_thhTsftd7PZtJt8UnVVlNpMWg84Qq1IlNd2MF4qmVIqavgArc6LzBV_iilD-PYexuXwznon-vfGNeA/XoRiCAoxKQVflSgweo3JrN9q6uBVbD9pao51jcsYG_I',
    '5ire': 'https://v5.airtableusercontent.com/v1/21/21/1697515200000/YZYxfnixL_2bd9sjVWixEA/yvxoayebMHJZjGr_aE_B8bFZoZPMug8goTUMRAzphmQmCKd1cctP1e4HtnuucjSUU1ziHt1NboWUyHhz0fCdjg/Iv8WGLtpppiNhfm0hFEIcvAdUyqPoI3ZNL4wKzJGt1Y',
    sys: 'https://assets.coingecko.com/coins/images/119/large/Syscoin.png?1696501494',
    sui: 'https://assets.coingecko.com/coins/images/26375/large/sui_asset.jpeg?1696525453',
    base: 'https://assets.coingecko.com/coins/images/13265/large/200x200green.png?1607650121',
    okc: 'https://v5.airtableusercontent.com/v1/21/21/1697515200000/SKYac7GYhFR11vWb-xeCvQ/nkk0koX1_71lGTplapafyygKYJ8DOhS-WQ5-BkYCYa-N_hypYARVp03XijCo0KLlhGhk9lcXqQyUCxyBUn6H5w/HIWl6JvXpUQHlEDaU56SegTXV-Zo_NGbZdYZfwZ4PRE',
    alchemy:
        'https://v5.airtableusercontent.com/v1/21/21/1697515200000/t6xHnRysgdKE208LIyiw1w/-8c1QSeVA75uQNKzcxufz4mJdMG_nVRPUfwPbXlG9VTo9sjyrHLH5Vb8UWlEsmZxPTqCE069_pgCdNyKeRuDEQ/MxnRI5WUOsWoUrqVFuTHnnlkLDe6WkSrG5fEkucRFz8',
    oak: 'https://v5.airtableusercontent.com/v1/21/21/1697515200000/pjQAAuZNjayH_pzqbE0reg/YtU2TCDTPAuD1HvdTFaFuHXRH8aAWha_biOxvV3s-u3u7qD2MISfKIbGG4QMrin9sxGN2MJ5gyayBTpMGXuQjg/9DysBx243a_HmGwkgHbflSHJLdAuJ1fznnnWWN9J9i0',
    btc: 'https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1696501400',
    eth: 'https://assets.coingecko.com/coins/images/279/large/ethereum.png?1696501628',
    bnb: 'https://assets.coingecko.com/coins/images/825/large/bnb-icon2_2x.png?1696501970',
    xrp: 'https://assets.coingecko.com/coins/images/31241/large/Pacman_Logo200x200.png?1696530067',
    usdc: 'https://assets.coingecko.com/coins/images/6319/large/usdc.png?1696506694',
    sol: 'https://assets.coingecko.com/coins/images/4128/large/solana.png?1696504756',
    ada: 'https://assets.coingecko.com/coins/images/975/large/cardano.png?1696502090',
    trx: 'https://assets.coingecko.com/coins/images/1094/large/tron-logo.png?1696502193',
    ton: 'https://assets.coingecko.com/coins/images/12334/large/ton.jpg?1696512162',
    matic: 'https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png?1696505277',
    dot: 'https://assets.coingecko.com/coins/images/12171/large/polkadot.png?1696512008',
    link: 'https://assets.coingecko.com/coins/images/877/large/chainlink-new-logo.png?1696502009',
    avax: 'https://assets.coingecko.com/coins/images/12559/large/Avalanche_Circle_RedWhite_Trans.png?1696512369',
    uni: 'https://assets.coingecko.com/coins/images/12504/large/uni.jpg?1696512319',
    xlm: 'https://assets.coingecko.com/coins/images/100/large/Stellar_symbol_black_RGB.png?1696501482',
    atom: 'https://assets.coingecko.com/coins/images/1481/large/cosmos_hub.png?1696502525',
    hbar: 'https://assets.coingecko.com/coins/images/3688/large/hbar.png?1696504364',
    fil: 'https://assets.coingecko.com/coins/images/12817/large/filecoin.png?1696512609',
    ldo: 'https://assets.coingecko.com/coins/images/13573/large/Lido_DAO.png?1696513326',
    icp: 'https://assets.coingecko.com/coins/images/14495/large/Internet_Computer_logo.png?1696514180',
    cro: 'https://assets.coingecko.com/coins/images/7310/large/cro_token_logo.png?1696507599',
    mkr: 'https://assets.coingecko.com/coins/images/1364/large/Mark_Maker.png?1696502423',
    apt: 'https://assets.coingecko.com/coins/images/26455/large/aptos_round.png?1696525528',
    vet: 'https://assets.coingecko.com/coins/images/1167/large/VET_Token_Icon.png?1696502256',
    op: 'https://assets.coingecko.com/coins/images/25244/large/Optimism.png?1696524385',
    arb: 'https://assets.coingecko.com/coins/images/16547/large/photo_2023-03-29_21.47.00.jpeg?1696516109',
    near: 'https://assets.coingecko.com/coins/images/10365/large/near.jpg?1696510367',
    aave: 'https://assets.coingecko.com/coins/images/12645/large/AAVE.png?1696512452',
    stx: 'https://assets.coingecko.com/coins/images/2069/large/Stacks_logo_full.png?1696503035',
    grt: 'https://assets.coingecko.com/coins/images/13397/large/Graph_Token.png?1696513159',
    wbt: 'https://assets.coingecko.com/coins/images/27045/large/wbt_token.png?1696526096',
    algo: 'https://assets.coingecko.com/coins/images/4380/large/download.png?1696504978',
    bit: 'https://assets.coingecko.com/coins/images/17627/large/rI_YptK8.png?1653983088',
    inj: 'https://assets.coingecko.com/coins/images/12882/large/Secondary_Symbol.png?1696512670',
    imx: 'https://assets.coingecko.com/coins/images/17233/large/immutableX-symbol-BLK-RGB.png?1696516787',
    snx: 'https://assets.coingecko.com/coins/images/3406/large/SNX.png?1696504103',
    eos: 'https://assets.coingecko.com/coins/images/738/large/eos-eos-logo.png?1696501893',
    xtz: 'https://assets.coingecko.com/coins/images/976/large/Tezos-logo.png?1696502091',
    sand: 'https://assets.coingecko.com/coins/images/12129/large/sandbox_logo.jpg?1696511971',
    axs: 'https://assets.coingecko.com/coins/images/13029/large/axie_infinity_logo.png?1696512817',
    mana: 'https://assets.coingecko.com/coins/images/878/large/decentraland-mana.png?1696502010',
    ftm: 'https://assets.coingecko.com/coins/images/4001/large/Fantom_round.png?1696504642',
    kava: 'https://assets.coingecko.com/coins/images/9761/large/kava.png?1696509822',
    neo: 'https://assets.coingecko.com/coins/images/480/large/NEO_512_512.png?1696501735',
    flow: 'https://assets.coingecko.com/coins/images/13446/large/5f6294c0c7a8cda55cb1c936_Flow_Wordmark.png?1696513210',
    kcs: 'https://assets.coingecko.com/coins/images/1047/large/sa9z79.png?1696502152',
    fxs: 'https://assets.coingecko.com/coins/images/13423/large/Frax_Shares_icon.png?1696513183',
    klay: 'https://assets.coingecko.com/coins/images/9672/large/klaytn.png?1696509742',
    xrd: 'https://assets.coingecko.com/coins/images/4374/large/Radix.png?1696504973',
    crv: 'https://assets.coingecko.com/coins/images/12124/large/Curve.png?1696511967',
    ape: 'https://assets.coingecko.com/coins/images/24383/large/apecoin.jpg?1696523566',
    cfx: 'https://assets.coingecko.com/coins/images/13079/large/3vuYMbjN.png?1696512867',
    rpl: 'https://assets.coingecko.com/coins/images/2090/large/rocket_pool_%28RPL%29.png?1696503058',
    ht: 'https://assets.coingecko.com/coins/images/2822/large/huobi-token-logo.png?1696503584',
    mina: 'https://assets.coingecko.com/coins/images/15628/large/JM4_vQ34_400x400.png?1696515261',
    cspr: 'https://assets.coingecko.com/coins/images/15279/large/casper.PNG?1696514931',
    dydx: 'https://assets.coingecko.com/coins/images/17500/large/hjnIm9bV.jpg?1696517040',
    nxm: 'https://assets.coingecko.com/coins/images/11810/large/NXMmain.png?1696511684',
    dash: 'https://assets.coingecko.com/coins/images/19/large/dash-logo.png?1696501423',
    zil: 'https://assets.coingecko.com/coins/images/2687/large/Zilliqa-logo.png?1696503475',
    comp: 'https://assets.coingecko.com/coins/images/10775/large/COMP.png?1696510737',
    btrst: null,
    '1inch': 'https://assets.coingecko.com/coins/images/13469/large/1inch-token.png?1696513230',
    ar: 'https://assets.coingecko.com/coins/images/4343/large/oRt6SiEN_400x400.jpg?1696504946',
    cake: 'https://assets.coingecko.com/coins/images/12632/large/pancakeswap-cake-logo_%281%29.png?1696512440',
    gno: 'https://assets.coingecko.com/coins/images/662/large/logo_square_simple_300px.png?1696501854',
    flr: 'https://assets.coingecko.com/coins/images/28624/large/FLR-icon200x200.png?1696527609',
    ilv: 'https://assets.coingecko.com/coins/images/14468/large/logo-200x200.png?1696514154',
    astr: 'https://assets.coingecko.com/coins/images/22617/large/astr.png?1696521933',
    cvx: 'https://assets.coingecko.com/coins/images/15585/large/convex.png?1696515221',
    celo: 'https://assets.coingecko.com/coins/images/11090/large/InjXBNx9_400x400.jpg?1696511031',
    hnt: 'https://assets.coingecko.com/coins/images/4284/large/Helium_HNT.png?1696504894',
    rose: 'https://assets.coingecko.com/coins/images/13162/large/rose.png?1696512946',
    lrc: 'https://assets.coingecko.com/coins/images/913/large/LRC.png?1696502034',
    okt: 'https://assets.coingecko.com/coins/images/13708/large/WeChat_Image_20220118095654.png?1696513453',
    ankr: 'https://assets.coingecko.com/coins/images/4324/large/U85xTl2.png?1696504928',
    zec: 'https://assets.coingecko.com/coins/images/486/large/circle-zcash-color.png?1696501740',
    ohm: 'https://assets.coingecko.com/coins/images/14483/large/token_OHM_%281%29.png?1696514169',
    dcr: 'https://assets.coingecko.com/coins/images/329/large/decred.png?1696501665',
    glm: 'https://assets.coingecko.com/coins/images/542/large/Golem_Submark_Positive_RGB.png?1696501761',
    ens: 'https://assets.coingecko.com/coins/images/19785/large/acatxTm8_400x400.jpg?1696519207',
    azero: 'https://assets.coingecko.com/coins/images/17212/large/gtmuTVa.png?1696516768',
    akt: 'https://assets.coingecko.com/coins/images/12785/large/akash-logo.png?1696512580',
    iotx: 'https://assets.coingecko.com/coins/images/3334/large/iotex-logo.png?1696504041',
    icx: 'https://assets.coingecko.com/coins/images/1060/large/icon-icx-logo.png?1696502164',
    yfi: 'https://assets.coingecko.com/coins/images/11849/large/yearn.jpg?1696511720',
    rvn: 'https://assets.coingecko.com/coins/images/3412/large/ravencoin.png?1696504108',
    lpt: 'https://assets.coingecko.com/coins/images/7137/large/logo-circle-green.png?1696507437',
    audio: 'https://assets.coingecko.com/coins/images/12913/large/AudiusCoinLogo_2x.png?1696512701',
    ksm: 'https://assets.coingecko.com/coins/images/9568/large/m4zRhP5e_400x400.jpg?1696509648',
    osmo: 'https://assets.coingecko.com/coins/images/16724/large/osmo.png?1696516298',
    waves: 'https://assets.coingecko.com/coins/images/425/large/waves.png?1696501700',
    ont: 'https://assets.coingecko.com/coins/images/3447/large/ONT.png?1696504138',
    lqty: 'https://assets.coingecko.com/coins/images/14665/large/200-lqty-icon.png?1696514340',
    bico: 'https://assets.coingecko.com/coins/images/21061/large/biconomy_logo.jpg?1696520444',
    axl: 'https://assets.coingecko.com/coins/images/27277/large/V-65_xQ1_400x400.jpeg?1696526329',
    glmr: 'https://assets.coingecko.com/coins/images/22459/large/glmr.png?1696521782',
    bal: 'https://assets.coingecko.com/coins/images/11683/large/Balancer.png?1696511572',
    ocean: 'https://assets.coingecko.com/coins/images/3687/large/ocean-protocol-logo.jpg?1696504363',
    canto: 'https://assets.coingecko.com/coins/images/26959/large/canto-network.png?1696526014',
    lsk: 'https://assets.coingecko.com/coins/images/385/large/Lisk_Symbol_-_Blue.png?1696501687',
    uma: 'https://assets.coingecko.com/coins/images/10951/large/UMA.png?1696510900',
    one: 'https://assets.coingecko.com/coins/images/4344/large/Y88JAze.png?1696504947',
    kuji: 'https://assets.coingecko.com/coins/images/20685/large/kuji-200x200.png?1696520085',
    zen: 'https://assets.coingecko.com/coins/images/691/large/horizen.png?1696501880',
    kda: 'https://assets.coingecko.com/coins/images/3693/large/Logo.png?1696504369',
    skl: 'https://assets.coingecko.com/coins/images/13245/large/SKALE_token_300x300.png?1696513021',
    ron: 'https://assets.coingecko.com/coins/images/20009/large/Ronin_Mark_Blue.png?1696519431',
    polyx: 'https://assets.coingecko.com/coins/images/23496/large/Polymesh-symbol.png?1696522706',
    sushi: 'https://assets.coingecko.com/coins/images/12271/large/512x512_Logo_no_chop.png?1696512101',
    gns: 'https://assets.coingecko.com/coins/images/19737/large/logo.png?1696519161',
    ckb: 'https://assets.coingecko.com/coins/images/9566/large/Nervos_White.png?1696509646',
    cet: 'https://assets.coingecko.com/coins/images/4817/large/coinex-token.png?1696505367',
    xvs: 'https://assets.coingecko.com/coins/images/12677/large/download.jpg?1696512482',
    vega: 'https://assets.coingecko.com/coins/images/15870/large/vega.PNG?1696515485',
    rif: 'https://assets.coingecko.com/coins/images/7460/large/RIF.png?1696507732',
    cqt: 'https://assets.coingecko.com/coins/images/14168/large/covalent-cqt.png?1696513886',
    celr: 'https://assets.coingecko.com/coins/images/4379/large/Celr.png?1696504978',
    pyr: 'https://assets.coingecko.com/coins/images/14770/large/1617088937196.png?1696514439',
    rad: 'https://assets.coingecko.com/coins/images/14013/large/radicle.png?1696513741',
    erg: 'https://assets.coingecko.com/coins/images/2484/large/Ergo.png?1696503303',
    core: 'https://assets.coingecko.com/coins/images/28938/large/coredao.png?1696527912',
    ever: 'https://assets.coingecko.com/coins/images/12783/large/everscale_badge_main_round_1x.png?1696512578',
    dodo: 'https://assets.coingecko.com/coins/images/12651/large/dodo_logo.png?1696512458',
    hxro: 'https://assets.coingecko.com/coins/images/7805/large/X_3D_Black_%2810%29.png?1696508044',
    alpha: 'https://assets.coingecko.com/coins/images/12738/large/Stella200x200-06.png?1696512537',
    syn: 'https://assets.coingecko.com/coins/images/18024/large/synapse_social_icon.png?1696517540',
    req: 'https://assets.coingecko.com/coins/images/1031/large/Request_icon_green.png?1696502140',
    spell: 'https://assets.coingecko.com/coins/images/15861/large/abracadabra-3.png?1696515477',
    acs: 'https://assets.coingecko.com/coins/images/28747/large/dR4FovX4_400x400.jpg?1696527727',
    gtc: 'https://assets.coingecko.com/coins/images/15810/large/gitcoin.png?1696515429',
    chr: 'https://assets.coingecko.com/coins/images/5000/large/Chromia.png?1696505533',
    scrt: 'https://assets.coingecko.com/coins/images/11871/large/secret_logo.png?1696511740',
    eul: 'https://assets.coingecko.com/coins/images/26149/large/YCvKDfl8_400x400.jpeg?1696525238',
    metis: 'https://assets.coingecko.com/coins/images/15595/large/metis.jpeg?1696515230',
    badger: 'https://assets.coingecko.com/coins/images/13287/large/badger_dao_logo.jpg?1696513059',
    tt: 'https://assets.coingecko.com/coins/images/4375/large/ThunderCore_Logo_Mark_Gradient_%283%29.png?1696504974',
    strk: 'https://assets.coingecko.com/coins/images/14607/large/Jw-36llq_400x400.jpg?1696514286',
    perp: 'https://assets.coingecko.com/coins/images/12381/large/60d18e06844a844ad75901a9_mark_only_03.png?1696512205',
    ghst: 'https://assets.coingecko.com/coins/images/12467/large/GHST.png?1696512286',
    combo: 'https://assets.coingecko.com/coins/images/4932/large/COMBO.jpg?1696505472',
    tlm: 'https://assets.coingecko.com/coins/images/14676/large/kY-C4o7RThfWrDQsLCAG4q4clZhBDDfJQVhWUEKxXAzyQYMj4Jmq1zmFwpRqxhAJFPOa0AsW_PTSshoPuMnXNwq3rU7Imp15QimXTjlXMx0nC088mt1rIwRs75GnLLugWjSllxgzvQ9YrP4tBgclK4_rb17hjnusGj_c0u2fx0AvVokjSNB-v2poTj0xT9BZRCbzRE3-l',
    bond: 'https://assets.coingecko.com/coins/images/12811/large/barnbridge.jpg?1696512604',
    aca: 'https://assets.coingecko.com/coins/images/20634/large/upOKBONH_400x400.jpg?1696520038',
    boba: 'https://assets.coingecko.com/coins/images/20285/large/Boba-200x200---white.png?1696519690',
    rare: 'https://assets.coingecko.com/coins/images/17753/large/RARE.jpg?1696517279',
    idle: null,
    evmos: 'https://assets.coingecko.com/coins/images/24023/large/evmos.png?1696523216',
    box: 'https://assets.coingecko.com/coins/images/6511/large/box-token.png?1547563043',
    rook: 'https://assets.coingecko.com/coins/images/13005/large/keeper_dao_logo.jpg?1604316506',
    mtrg: 'https://assets.coingecko.com/coins/images/11848/large/meter.png?1696511719',
    movr: 'https://assets.coingecko.com/coins/images/17984/large/9285.png?1696517502',
    ubq: null,
    zbc: 'https://assets.coingecko.com/coins/images/24342/large/zebec.jpeg?1696523526',
    looks: 'https://assets.coingecko.com/coins/images/22173/large/circle-black-256.png?1696521517',
    aura: 'https://assets.coingecko.com/coins/images/30364/large/aura.jpeg?1696529261',
    lyra: 'https://assets.coingecko.com/coins/images/21490/large/Add-a-heading-26.png?1696520850',
    quick: 'https://assets.coingecko.com/coins/images/25393/large/quickswap.png?1696524525',
    amb: 'https://assets.coingecko.com/coins/images/1041/large/amb.png?1696502148',
    alcx: 'https://assets.coingecko.com/coins/images/14113/large/Alchemix.png?1696513834',
    alpaca: 'https://assets.coingecko.com/coins/images/14165/large/Logo200.png?1696513884',
    tlos: 'https://assets.coingecko.com/coins/images/7588/large/TLOS_200.png?1696507848',
    clv: 'https://assets.coingecko.com/coins/images/15278/large/photo_2022-03-24_10-22-33.jpg?1696514930',
    rei: 'https://assets.coingecko.com/coins/images/25240/large/c51_j0aB_400x400.jpg?1696524381',
    bsw: 'https://assets.coingecko.com/coins/images/16845/large/biswap.png?1696516413',
    abr: 'https://assets.coingecko.com/coins/images/18690/large/abr.png?1640742053',
    fort: 'https://assets.coingecko.com/coins/images/25060/large/Forta_lgo_%281%29.png?1696524210',
    dpx: 'https://assets.coingecko.com/coins/images/16652/large/DPX_%281%29.png?1696516213',
    qi: 'https://assets.coingecko.com/coins/images/15329/large/qidao.jpeg?1697093140',
    inst: 'https://assets.coingecko.com/coins/images/14688/large/30hFM0-n_400x400.jpg?1696514361',
    data: 'https://assets.coingecko.com/coins/images/17869/large/DATA_new_symbol_3x.png?1696517392',
    aurora: 'https://assets.coingecko.com/coins/images/20582/large/aurora.jpeg?1696519989',
    fra: 'https://assets.coingecko.com/coins/images/14725/large/200x200_%2813%29.png?1696514395',
    vlx: 'https://assets.coingecko.com/coins/images/9651/large/logo_blue.png?1696509720',
    map: 'https://assets.coingecko.com/coins/images/10085/large/53819931.png?1696510114',
    df: 'https://assets.coingecko.com/coins/images/9709/large/xlGxxIjI_400x400.jpg?1696509776',
    farm: 'https://assets.coingecko.com/coins/images/12304/large/FARM_200x200.png?1696512134',
    bifi: 'https://assets.coingecko.com/coins/images/13671/large/ysYIu7Q.png?1696513420',
    pal: 'https://assets.coingecko.com/coins/images/24558/large/Circle-Logo.png?1648180728',
    banana: 'https://assets.coingecko.com/coins/images/14870/large/banana.png?1696514534',
    cru: 'https://assets.coingecko.com/coins/images/12549/large/sAB3KVzD_400x400.jpg?1696512360',
    pivx: 'https://assets.coingecko.com/coins/images/548/large/PIVX-Shield.png?1696501766',
    bzz: 'https://assets.coingecko.com/coins/images/16509/large/Circle_Orange_onWhite.png?1696516071',
    xpr: 'https://assets.coingecko.com/coins/images/10941/large/XPR.jpg?1696510891',
    fsn: 'https://assets.coingecko.com/coins/images/2515/large/Fusion_200x200.png?1696503329',
    giv: 'https://assets.coingecko.com/coins/images/21792/large/GIVToken_200x200.png?1640055088',
    dimo: 'https://assets.coingecko.com/coins/images/28383/large/Token_Logo.png?1696527383',
    hdx: 'https://assets.coingecko.com/coins/images/13929/large/hydradx_logo.png?1696513669',
    juno: 'https://assets.coingecko.com/coins/images/19249/large/Juno_Logo_%28Salmon%29_%282%29.png?1696518694',
    rsv: 'https://assets.coingecko.com/coins/images/10952/large/rsv.png?1637983297',
    cvp: 'https://assets.coingecko.com/coins/images/12266/large/Powerpool.jpg?1696512097',
    rari: 'https://assets.coingecko.com/coins/images/11845/large/Rari.png?1696511716',
    cow: 'https://assets.coingecko.com/coins/images/24384/large/cow.png?1696523567',
    dora: 'https://assets.coingecko.com/coins/images/14478/large/dora_logo.jpg?1616410086',
    pnt: 'https://assets.coingecko.com/coins/images/11659/large/pNetwork.png?1696511550',
    bog: 'https://assets.coingecko.com/coins/images/15980/large/bog.png?1638183823',
    cube: 'https://assets.coingecko.com/coins/images/10687/large/CUBE_icon.png?1696510656',
    fuse: 'https://assets.coingecko.com/coins/images/10347/large/fuse.png?1696510348',
    gbyte: 'https://assets.coingecko.com/coins/images/561/large/byteball.png?1696501768',
    mnde: 'https://assets.coingecko.com/coins/images/18867/large/MNDE.png?1696518327',
    oax: 'https://assets.coingecko.com/coins/images/853/large/OAXlogo.png?1696501987',
    bstn: 'https://assets.coingecko.com/coins/images/24228/large/1_hm7Nmrw_PmniLj3zlzIdZA.png?1646949451',
    cult: 'https://assets.coingecko.com/coins/images/23331/large/quxZPrbC_400x400.jpg?1696522547',
    push: 'https://assets.coingecko.com/coins/images/14769/large/aiOxYOJI_400x400.jpeg?1696514438',
    mph: 'https://assets.coingecko.com/coins/images/13137/large/yfU-_Tcj_400x400.png?1605581509',
    klima: 'https://assets.coingecko.com/coins/images/19169/large/Klima-Token.png?1696518618',
    sov: 'https://assets.coingecko.com/coins/images/16248/large/sov.PNG?1696515847',
    gear: 'https://assets.coingecko.com/coins/images/21630/large/gear.png?1696520990',
    rai: 'https://assets.coingecko.com/coins/images/14004/large/RAI-logo-coin.png?1696513733',
    gari: 'https://assets.coingecko.com/coins/images/22615/large/gari.png?1696521931',
    index: 'https://assets.coingecko.com/coins/images/12729/large/index.png?1696512528',
    note: 'https://assets.coingecko.com/coins/images/20282/large/NOTE-340x340.png?1696519687',
    well: 'https://assets.coingecko.com/coins/images/26133/large/WELL.png?1696525221',
    temp: 'https://assets.coingecko.com/coins/images/20547/large/Tempus_CoinGecko_200x200.png?1696519957',
    eco: 'https://assets.coingecko.com/coins/images/27961/large/_ECO.png?1696526980',
    boo: 'https://assets.coingecko.com/coins/images/15223/large/logo_200x200.png?1696514878',
    hop: 'https://assets.coingecko.com/coins/images/25445/large/hop.png?1696524577',
    ring: 'https://assets.coingecko.com/coins/images/9443/large/RING.png?1696509535',
    bank: 'https://assets.coingecko.com/coins/images/15227/large/j4WEJrwU.png?1696514882',
    clo: 'https://assets.coingecko.com/coins/images/4051/large/CLO.png?1696504686',
    stc: 'https://assets.coingecko.com/coins/images/20921/large/stc.png?1696520311',
    air: 'https://assets.coingecko.com/coins/images/23548/large/altair.jpeg?1696522755',
    mntl: 'https://assets.coingecko.com/coins/images/25181/large/thumbnail.png?1696524326',
    safe: 'https://assets.coingecko.com/coins/images/5011/large/Safecoin_Logo.png?1696505543',
    belt: 'https://assets.coingecko.com/coins/images/14319/large/belt_logo.jpg?1696514007',
    pool: 'https://assets.coingecko.com/coins/images/14003/large/PoolTogether.png?1696513732',
    mta: 'https://assets.coingecko.com/coins/images/11846/large/mStable.png?1696511717',
    edg: 'https://assets.coingecko.com/coins/images/8452/large/logo-edgeware.png?1696508638',
    intr: 'https://assets.coingecko.com/coins/images/26180/large/Interlay-Coinbase-2.png?1696525267',
    tulip: 'https://assets.coingecko.com/coins/images/15764/large/TqrUdBG.png?1696515389',
    beets: 'https://assets.coingecko.com/coins/images/19158/large/beets-icon-large.png?1696518608',
};

export const getAssetLogo = (slug: string) => {
    if (!slug) return '';
    return (
        logos[slug as AssetSymbol] ||
        logos[slug.toLowerCase() as AssetSymbol] ||
        logoImages[slug.toLowerCase() as keyof typeof logoImages]
    );
};
