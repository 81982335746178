import { useState } from 'react';

type LocalStorageValue = object | string | number | boolean | null;

const useLocalStorage = <T extends LocalStorageValue = string>(
    key: string,
    initialValue?: T,
    expirationInMs: number = Infinity, // Default to no expiration (lives forever)
) => {
    const clearValue = () => {
        try {
            window.localStorage.removeItem(key);
        } catch (error) {
            console.error('Error clearing local storage:', error);
        }
    };

    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        if (typeof window === 'undefined') {
            return initialValue;
        }
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            if (!item) return initialValue;

            const { value, timestamp } = JSON.parse(item);

            // If the item is expired, remove it and return initialValue
            if (Date.now() - timestamp > expirationInMs) {
                clearValue();
                return initialValue;
            }

            return value;
        } catch (error) {
            // If error also return initialValue
            // eslint-disable-next-line no-console
            console.error(error);
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: ((prev: T) => T) | T) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage with a timestamp
            window.localStorage.setItem(
                key,
                JSON.stringify({
                    value: valueToStore,
                    timestamp: Date.now(),
                }),
            );
        } catch (error) {
            // A more advanced implementation would handle the error case
            // eslint-disable-next-line no-console
            console.error(error);
        }
    };

    return { storedValue, setValue, clearValue };
};

export { useLocalStorage };
