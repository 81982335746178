import { startCase } from 'lodash-es';

import type { AnyObj } from '../../typescript';

type AnalyticsUser = {
    [key: string]: unknown;
    $email: string;
    $name: string;
};

const mapUserToAnalyticsUser = <T extends AnyObj>(user: T): AnalyticsUser => {
    const analyticsUser: AnalyticsUser = {
        $email: user.email,
        $name: `${user.firstname} ${user.lastname}`,
    };

    const keys = Object.keys(user).filter(key => key !== 'email');
    keys.forEach(key => {
        if (key === 'firstname') key = 'first name';
        if (key === 'lastname') key = 'last name';
        analyticsUser[startCase(key)] = user[key as keyof T];
    });

    return analyticsUser;
};

export { mapUserToAnalyticsUser };
