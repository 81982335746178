import { forwardRef } from 'react';

import { cn, safelySpreadDOMProps } from '../../style-system';
import { TVComponentWithElementProps } from '../../style-system/models';
import { safeAttr } from '../../utils';
import type { IconComponent } from '../icon';
import { Spinner } from '../spinner';
import { Text } from '../text';

import { buttonStyles } from './button.styles';

type CommonButtonProps = TVComponentWithElementProps<'button', typeof buttonStyles> & {
    /** If loading is `true` the button will be disabled */
    loading?: boolean;
    loadingText?: string;
    /**
     * Passing `true` displays the button's active state.
     * Useful for trigger buttons e.g. Popover Trigger
     **/ active?: boolean;
    children: React.ReactNode;
};

type ButtonProps =
    | (CommonButtonProps & {
          /** Displays an icon on the left side of the button label */
          leadingIcon?: never;
          /** Displays an icon on the right side of the button label */
          trailingIcon?: IconComponent;
      })
    | (CommonButtonProps & {
          /** Displays an icon on the left side of the button label */
          leadingIcon?: IconComponent;
          /** Displays an icon on the right side of the button label */
          trailingIcon?: never;
      });

const iconStyles = cn('btn-icon w-4 h-4 aspect-square group-data-active:text-primary-focus');
const spinnerWrapperStyles = cn('w-max flex gap-2.5 items-center');

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
    {
        variant = 'fill',
        intent = 'neutral',
        borderRadius = 'full',
        size = 'sm',
        loading = false,
        loadingText = 'Loading...',
        leadingIcon: LeadingIcon,
        trailingIcon: TrailingIcon,
        type,
        active = false,
        children,
        className,
        ...props
    },
    ref,
) {
    const isDisabled = !!props.disabled || loading;
    const baseStyles = buttonStyles({
        ...props,
        intent,
        variant,
        borderRadius,
        disabled: isDisabled,
        size,
        className,
    });
    const showSpinner = !baseStyles.includes('data-hide-spinner');

    return (
        <button
            {...safelySpreadDOMProps(props)}
            type={type}
            className={baseStyles}
            disabled={isDisabled}
            ref={ref}
            {...safeAttr(active, 'active')}
        >
            {loading ? (
                <span className={spinnerWrapperStyles}>
                    {showSpinner && <Spinner size={baseStyles.includes('data-sz-md') ? 6 : 4} />}
                    <Text>{loadingText}</Text>
                </span>
            ) : (
                <>
                    {LeadingIcon && <LeadingIcon className={iconStyles} />}
                    {children}
                    {TrailingIcon && <TrailingIcon className={iconStyles} />}
                </>
            )}
        </button>
    );
});

export type { ButtonProps };
export { Button };
