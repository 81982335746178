import { forwardRef } from 'react';
import { useController } from 'react-hook-form';

import { useMergeRefs } from '../../../hooks';
import { CombineRefWithProps } from '../../../models';
import { callAll } from '../../../utils';
import { Combobox, type CreatableComboboxProps } from '../combobox';
import { Field } from '../field';
import type { FieldOrientationOptions } from '../field/context/field-context.model';

import type { FieldValues, FormFieldWithControllerProps } from './form.model';

type FormCreatableComboboxProps<T extends FieldValues> = FormFieldWithControllerProps<T, CreatableComboboxProps> &
    FieldOrientationOptions<true>;

export declare function FormCreatableComboboxComponent<T extends FieldValues>(
    props: CombineRefWithProps<HTMLInputElement, FormCreatableComboboxProps<T>>,
): JSX.Element;

const FormCreatableCombobox = forwardRef<HTMLInputElement, FormCreatableComboboxProps<any>>(
    function FormCreatableCombobox(
        {
            name,
            control,
            options,
            errorMessage,
            hideErrorMessage = false,
            helpText,
            onValueChange,
            disabled,
            readOnly,
            required,
            defaultValue,
            label,
            id,
            size,
            orientation,
            fullWidth,
            ...rest
        },
        ref,
    ) {
        const {
            field,
            fieldState: { error },
        } = useController({
            name,
            control,
            rules: { required },
            disabled,
            defaultValue,
        });

        const internalRef = useMergeRefs(field.ref, ref);

        return (
            <Field
                size={size}
                invalid={!!error?.message}
                fullWidth={fullWidth}
                required={required}
                readOnly={readOnly}
                id={id}
                orientation={orientation}
            >
                <Field.Orientation>
                    {label ? <Field.Label>{label}</Field.Label> : null}
                    <Combobox.Creatable
                        {...rest}
                        ref={internalRef}
                        name={field.name}
                        options={options}
                        value={field.value ?? ''}
                        defaultValue={defaultValue}
                        onValueChange={callAll(field.onChange, onValueChange)}
                    />
                </Field.Orientation>
                {hideErrorMessage ? null : <Field.ErrorMessage>{errorMessage ?? error?.message}</Field.ErrorMessage>}
                {helpText ? <Field.HelpText>{helpText}</Field.HelpText> : null}
            </Field>
        );
    },
) as unknown as typeof FormCreatableComboboxComponent;

export { FormCreatableCombobox };
