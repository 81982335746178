import { defineEndpoints } from '../../config/define-endpoints';
import { DataWithMeta } from '../../config/type-helpers';

type CompanyMemberShape = {
    userId: number;
    name: string;
    email: string;
    imageUrl: string;
    role: '32' | '1';
};

type CompanyShape = {
    name: string;
    subscriptionStatus: string;
    members: CompanyMemberShape[];
    error?: string;
};

type CompanyInviteShape = {
    id: number;
    inviteeId: number | null;
    invitorId: number;
    clientId: number;
    email: string;
    role: '32' | '1';
    acceptedAt: number | null;
    createdAt: number;
    deletedAt: number | null;
};

type GetCompanyResponse = DataWithMeta<CompanyShape>;

type GetCompanyInvitesResponse = DataWithMeta<CompanyInviteShape[]>;

type PostUpdateCompanyRequest = {
    name: string;
};

type PostAddMemberRequest = {
    email: string;
    role: string;
};

type AcceptInviteRequest = {
    token: string;
    sessionToken: string;
};

type PostAddMemberToCompanyResponse = DataWithMeta<{
    status: number;
    message: string;
    user?: CompanyMemberShape;
}>;

type AcceptInviteResponse = DataWithMeta<{
    status: number;
    message: string;
}>;

type DeleteMemberRequest = {
    email: string;
};

type DeleteInviteRequest = {
    id: number;
};

type DeleteMemberResponse = DataWithMeta<{
    status: number;
    message: string;
    user?: CompanyMemberShape;
}>;

type DeleteInviteResponse = DataWithMeta<{
    status: number;
    message: string;
    invite?: CompanyInviteShape;
}>;

const COMPANY_ENDPOINTS = defineEndpoints(template => ({
    GET: {
        COMPANY: template('/api/v1/company'),
        INVITES: template('/api/v1/company/invite'),
    },
    POST: {
        UPDATE_COMPANY: template('/api/v1/company'),
        INVITE_USER: template('/api/v1/company/invite'),
        ACCEPT_INVITE: template('/api/v1/company/invite/accept'),
    },
    DELETE: {
        MEMBER: template('/api/v1/company/member'),
        INVITE: template('/api/v1/company/invite/{id}'),
    },
}));

export type {
    AcceptInviteRequest,
    AcceptInviteResponse,
    CompanyInviteShape,
    CompanyMemberShape,
    DeleteInviteRequest,
    DeleteInviteResponse,
    DeleteMemberRequest,
    DeleteMemberResponse,
    GetCompanyInvitesResponse,
    GetCompanyResponse,
    PostAddMemberRequest,
    PostAddMemberToCompanyResponse,
    PostUpdateCompanyRequest,
};
export { COMPANY_ENDPOINTS };
